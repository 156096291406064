import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '.';


interface FilterState {
    all: boolean;
    active: boolean;
    inactive: boolean;
    deleted: boolean;
    devices: string[];
}

const initialState: FilterState = {
    all: false,
    active: false,
    inactive: false,
    deleted: false,
    devices: [],
};

export const filterState = createSlice({
    name: 'filter',
    initialState,
    reducers: {
        switchAllFilter: (state) => {
            state.all = !state.all;
            if (state.all) {
                state.active = false;
                state.inactive = false;
                state.deleted = false;
            }
        },
        addDeviceFilter: (state, { payload }) => {
            if (!state.devices.includes(payload)) {
                state.devices = [...state.devices, payload].filter(serial => serial !== 'all');
            }
        },
        removeDeviceFilter: (state, { payload }) => {
            state.devices = state.devices.filter(device => device !== payload);
        },
        addAllDevicesFilter: (state, { payload }) => {
            state.devices = payload;
        },
        clearDeviceFilter: (state) => {
            state.devices = ['all'];
        },
        switchActiveFilter: (state) => {
            state.active = !state.active;
            state.all = false;
        },
        switchInactiveFilter: (state) => {
            state.inactive = !state.inactive;
            state.all = false;
        },
        switchDeletedFilter: (state) => {
            state.deleted = !state.deleted;
            state.all = false;
        },
        resetFilter: (state) => {
            state.all = false;
            state.active = false;
            state.inactive = false;
            state.deleted = false;
        },
    },
});

export const { addAllDevicesFilter, clearDeviceFilter, addDeviceFilter, removeDeviceFilter, resetFilter, switchAllFilter, switchActiveFilter, switchInactiveFilter, switchDeletedFilter } = filterState.actions;
export const selectDeviceFilter = (state: RootState) => state.filter.devices;
export const selectAllFilter = (state: RootState) => state.filter.all;
export const selectActiveFilter = (state: RootState) => state.filter.active;
export const selectInactiveFilter = (state: RootState) => state.filter.inactive;
export const selectDeletedFilter = (state: RootState) => state.filter.deleted



