import { Stack } from "@mui/material";
import { MappedTherapySessionData } from "../../../types/types";
import DetailsBox from "./DetailsBox";
import { useAppSelector } from "../../../store";
import { selectDeviceFilter } from "../../../store/filterState";
import NoSessionFoundMessage from "./NoSessionFoundMessage";

export interface Props {
    assignedSessions: MappedTherapySessionData[];
}

const AssignedSessions = ({ assignedSessions }: Props) => {
    const filteredDevices = useAppSelector(selectDeviceFilter);

    const sortedData = (data?: MappedTherapySessionData[]) => {
        if (!data) {
            return [];
        }

        return data.slice().sort((a, b) => {
            if (a.exerciseStartDate > b.exerciseStartDate) {
                return -1;
            }
            if (a.exerciseStartDate < b.exerciseStartDate) {
                return 1;
            }
            return 0;
        });

    };

    const getFilteredData = () => {
        if (filteredDevices.length > 0 && !filteredDevices.includes('all')) {
            return assignedSessions.filter(session => filteredDevices.includes(session.serial));
        }
        return assignedSessions;
    };

    const getSessionBoxes = () => {
        const filteredSessions = sortedData(getFilteredData());
        const sessions = filteredSessions.map((session: MappedTherapySessionData) =>
            <DetailsBox key={session.id} session={session} />
        );
        if (sessions.length > 0) {
            return (<Stack alignSelf="start" direction="column" sx={{ paddingLeft: '10px', paddingTop: '10px', paddingRight: '10px', width: '100%', overflow: 'auto' }} >
                {sessions}
            </Stack>);
        }
        return <div style={{ paddingLeft: '20px', paddingRight: '20px' }}><NoSessionFoundMessage /></div>;
    };
    return getSessionBoxes();
};

export default AssignedSessions;
