import { Grid, Stack, Typography } from "@mui/material";
import { getUniqueDeviceKeys } from "../../../../hooks/useFetchAssignedSessions";
import { MappedTherapySessionData } from "../../../../types/types";
import { useState } from "react";
import theme from "../../../../assets/theme";
import LyraIcon from "../../../../icons/LyraIcon";
import { getDeviceKey, getExerciseDuration, millisToHoursString } from "../../../ResultAssignment/fragments/SessionDetailsBox";
import NoSessionFoundMessage from "../NoSessionFoundMessage";

type Props = {
    patientId: string;
    assignedSessions: MappedTherapySessionData[];
    setCurrentDeviceKey: (devcieKey?: string) => void;
};

const TotalsHeader = ({ setCurrentDeviceKey, assignedSessions, patientId }: Props) => {
    const [currentDevice, setCurrentDevice] = useState<string | undefined>();
    const unique = getUniqueDeviceKeys(assignedSessions);

    const setDeviceKey = (deviceKey?: string) => {
        setCurrentDevice(deviceKey);
        setCurrentDeviceKey(deviceKey);
    };

    const getTotalsHeader = () => {
        return (
            <Grid key="totals" onClick={() => { setDeviceKey(); }} item xs={3} sx={{
                cursor: 'pointer',
                backgroundColor: `${currentDevice ? 'white' : theme.palette.lightgreen.main}`,
                borderRight: `solid 1px ${theme.palette.linkgray.main}`,
                paddingTop: '10px',
                paddingLeft: '10px',
                borderBottomLeftRadius: '10px',
                borderTopLeftRadius: '10px'
            }}>
                <Stack>< Typography fontWeight="bold">Gesamt</Typography></Stack >
                <Stack style={{ paddingTop: '5px' }}><Typography fontWeight="bold">{assignedSessions.length}</Typography></Stack>
                <Stack>Einheiten</Stack>
                <Stack style={{ paddingTop: '5px' }}><Typography fontWeight="bold">{'hours'}</Typography></Stack>
                <Stack>Stunden</Stack>
            </Grid >
        );
    };

    const getSession = (deviceKey: string) => {
        const session = assignedSessions.find((session) => `${getDeviceKey(session)}` === deviceKey);
        return session;
    };

    const getResultsHeader = (deviceKey: string) => {
        const sessions = assignedSessions.filter((session) => `${session.type}-${session.therapyMethod}` === deviceKey);
        let hours = 0;
        sessions.forEach(session => {
            hours = hours + getExerciseDuration(session);
        });

        const session = getSession(deviceKey);

        return (
            <Grid key={deviceKey} onClick={() => { setDeviceKey(deviceKey); }} item xs={3} sx={{
                cursor: 'pointer',
                backgroundColor: `${currentDevice === deviceKey ? theme.palette.lightgreen.main : 'white'}`,
                borderRight: `solid 1px ${theme.palette.linkgray.main}`,
                paddingTop: '10px',
                paddingLeft: '10px'
            }}>
                <div style={{ textWrap: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', fontWeight: 'bold' }}>{session?.therapyMethod ?? '?'}</div>
                <Stack style={{ paddingTop: '5px' }}><Typography fontWeight="bold">{sessions.length}</Typography></Stack>
                <Stack>Einheiten</Stack>

                <div style={{ paddingTop: '5px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Stack direction="column" alignItems="flex-start">
                        <Stack><Typography fontWeight="bold">{millisToHoursString(hours)}</Typography></Stack>
                        <Stack>Stunden</Stack>
                    </Stack>
                    <LyraIcon style={{ fontSize: '40px' }} />
                </div>

            </Grid >
        );
    };

    const getComponent = () => {
        if (!assignedSessions || assignedSessions.length === 0) {
            return <NoSessionFoundMessage />;
        }
        if (assignedSessions.length > 1) {
            return (
                <Grid container style={{ borderRadius: '10px', height: '150px', backgroundColor: 'white' }}>
                    {getTotalsHeader()}
                    {unique.map((session) => getResultsHeader(session))}
                    <Grid key="dummy" item xs={3}
                        sx={{
                            borderBottomRightRadius: '10px',
                            borderTopRightRadius: '10px',
                            paddingTop: '10px',
                            paddingLeft: '10px'
                        }}>
                        <Stack>
                            <Typography noWrap textOverflow="ellipsis" fontWeight="bold"></Typography>
                        </Stack>
                        <Stack style={{ paddingTop: '5px' }}>
                            <Typography fontWeight="bold"></Typography>
                        </Stack>
                        <Stack></Stack>
                        <Stack style={{ paddingTop: '10px' }}>
                            <Typography fontWeight="bold"></Typography>
                        </Stack>
                        <Stack ></Stack>
                    </Grid >

                </Grid>
            );

        }
        return <></>;
    };


    return getComponent();
};

export default TotalsHeader;
