import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { filterState } from './filterState';
import { authSlice } from './authSlice';
import { uiState } from './uiState';

import { emptyBaseApi } from '../api/emptyBaseApi';
import { listStateSlice } from './listStateSlice';
import { historyState } from './historyState';

export const appReducers = combineReducers({
    filter: filterState.reducer,
    list: listStateSlice.reducer,
    history: historyState.reducer,
    ui: uiState.reducer,
    auth: authSlice.reducer,
    [emptyBaseApi.reducerPath]: emptyBaseApi.reducer,
});

export const store = configureStore({
    reducer: appReducers,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat([emptyBaseApi.middleware]),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useAppSelector = useSelector;
