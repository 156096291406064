import { Route, Routes } from "react-router-dom";
import Manufacturer from "../components/Manufacturer/Manufacturer";
import Retailer from "../components/Retailer/Retailer";
import LockedAccounts from "../components/LockedAccounts/LockedAccounts";
import User from "../components/User/User";
import Clinic from "../components/Clinic/Clinic";
import Device from "../components/Device/Device";
import ResultAssignment from "../components/ResultAssignment/ResultAssignment";
import Patients from "../components/Patients/Patients";
import PatientInfo from "../components/Patients/fragments/PatientInfo/PatientInfo";
import TrainingsResults from "../components/Patients/fragments/TrainingsResults/TrainingsResults";
import TrainingsSettings from "../components/Patients/fragments/TrainingSettings/TrainingsSettings";
import PatientResultAssignment from "../components/Patients/fragments/ResultAssignment/PatientResultAssignment";
import DeveloperPage from "../components/DeveloperPage/DeveloperPage";
import LoginPage from "../components/LoginPage/LoginPage";
import DeviceLog from "../components/DeviceLog/DeviceLog";
const useRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<LoginPage />} />
            <Route path="/patients/*" element={
                <Routes>
                    <Route path="/" element={<Patients />} />
                    <Route path="info" element={<PatientInfo />} />
                    <Route path="settings" element={<TrainingsSettings />} />
                    <Route path="assignment" element={<PatientResultAssignment />} />
                    <Route path="results" element={<TrainingsResults />} />
                </Routes>
            } >
            </Route>
            <Route path="developer" element={<DeveloperPage />} />
            <Route path="user" element={<User />} />
            <Route path="locked-accounts" element={<LockedAccounts />} />
            <Route path="manufacturer" element={<Manufacturer />} />
            <Route path="retailer" element={<Retailer />} />
            <Route path="clinic" element={<Clinic />} />
            <Route path="devices" element={<Device />} />
            <Route path="device-logs" element={<DeviceLog />} />
            <Route path="result-assignment" element={<ResultAssignment />} />
            <Route path="*" element={<div>Path not found!</div>} />
        </Routes>
    );

};

export default useRoutes;
