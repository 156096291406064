import { Checkbox, CheckboxProps } from "@mui/material";
import { ChangeEvent } from "react";
import { MappedTherapySessionData } from "../../../../types/types";
import { addAllDevicesFilter, addDeviceFilter, clearDeviceFilter, removeDeviceFilter, selectDeviceFilter } from "../../../../store/filterState";
import { useAppDispatch, useAppSelector } from "../../../../store";

type Props = CheckboxProps & {
    deviceSerial: string;
    assignedSessions: MappedTherapySessionData[];
};

const SessionsFilterCheckbox = ({ assignedSessions, deviceSerial }: Props) => {
    const filteredDevices = useAppSelector(selectDeviceFilter);
    const dispatch = useAppDispatch();
    const sessions = assignedSessions.map((session) => session.serial);
    const unique = [...new Set(sessions)];

    const doDispatch = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.currentTarget.value === 'all') {
            if (isChecked()) {
                dispatch(addAllDevicesFilter(unique));
                return;
            }
            dispatch(clearDeviceFilter());
            return;
        }
        if (isChecked()) {
            dispatch(removeDeviceFilter(e.currentTarget.value));
            return;
        }
        dispatch(addDeviceFilter(e.currentTarget.value));
    };


    const isChecked = () => {
        return filteredDevices.includes(deviceSerial);
    };

    return (<Checkbox checked={isChecked()} onChange={doDispatch} value={deviceSerial} />);
};

export default SessionsFilterCheckbox;
