import { t } from "i18next";
import theme, { largeFont } from "../../../assets/theme";
import { Box } from "@mui/material";

type Props = {
    showBorder?: boolean;
};
const NoSessionFoundMessage = ({ showBorder = true }: Props) => {
    const borderStyle = showBorder ? `solid 1px ${theme.palette.inactive.main}` : 'none';
    return <Box style={{
        borderTop: borderStyle, padding: '20px', height: '100%', fontSize: largeFont, display: 'flex', alignItems: 'center', justifyContent: 'center'
    }}>{t('no-session-found-message')}</Box>;

};

export default NoSessionFoundMessage;
