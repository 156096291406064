import { useEffect, useState } from "react";
import { useAppSelector } from "../../../store";
import { selectAuthState, selectIsClinicAdmin, selectIsManufacturerAdmin, selectIsRetailerAdmin } from "../../../store/authSlice";
import { DialogBox } from "../ConfirmDialog/VersionsinfoDialog";
import EditCloudMember from "./EditCloudMember";
import { CloudMember } from "../../../types/types";
import { useFetchManufacturerMutation, useUpdateManufacturerMutation } from "../../../api/manufacturerApi";
import { useFetchRetailerMutation, useUpdateRetailerMutation } from "../../../api/retailerApi";
import { useFetchClinicMutation, useUpdateClinicMutation } from "../../../api/clinicApi";

type DialogProps = {
    okCallback: () => void;
};
const EditCloudMemberDialog = ({ okCallback }: DialogProps) => {
    const isManufacturerAdmin = useAppSelector(selectIsManufacturerAdmin);
    const isRetailerAdmin = useAppSelector(selectIsRetailerAdmin);
    const isClinicAdmin = useAppSelector(selectIsClinicAdmin);
    const authState = useAppSelector(selectAuthState);
    const [getManufacturer] = useFetchManufacturerMutation();
    const [getRetailer] = useFetchRetailerMutation();
    const [getClinic] = useFetchClinicMutation();
    const [data, setData] = useState<CloudMember>();
    const [updateManufacturer] = useUpdateManufacturerMutation();
    const [updateRetailer] = useUpdateRetailerMutation();
    const [updateClinic] = useUpdateClinicMutation();

    useEffect(() => {
        if (isManufacturerAdmin && authState.manufacturerId) {
            getManufacturer(authState.manufacturerId).then(result => {
                setData(result.data);
            });
        }
        if (isRetailerAdmin && authState.retailerId) {
            getRetailer(authState.retailerId).then(result => {
                setData(result.data);
            });
        }
        if (isClinicAdmin && authState.clinicId) {
            getClinic(authState.clinicId).then(result => {
                setData(result.data);
            });
        }
    }, []);

    if (!data) {
        return <div>No data found!</div>;
    }

    const update = async (params: any) => {
        if (isManufacturerAdmin) {
            updateManufacturer(params);
        }
        if (isRetailerAdmin) {
            updateRetailer(params);
        }
        if (isClinicAdmin) {
            updateClinic(params);
        }
        okCallback();
    };


    return <DialogBox>
        <EditCloudMember data={data}
            setPage={okCallback}
            update={update}
        />
    </DialogBox>;
};

export default EditCloudMemberDialog;
