import { useEffect, useState } from "react";
import { CloudMember } from "../types/types";
import { useGetRetailerListQuery } from "../api/retailerApi";

const useFetchRetailers = () => {
    const { isLoading, data, error } = useGetRetailerListQuery(undefined, { refetchOnMountOrArgChange: true });
    const [retailers, setRetailers] = useState<CloudMember[]>();
    const [isRetailersLoading, setIsRetailersLoading] = useState(false);
    const [retailersError, setRetailersError] = useState<string | null>(null);

    useEffect(() => {
        setIsRetailersLoading(isLoading);
        if (error) {
            setIsRetailersLoading(false);
            setRetailersError(JSON.stringify(error));
        } else if (!isLoading && data) {
            if (JSON.stringify(data) !== JSON.stringify(retailers)) {
                setRetailers(data);
                setRetailersError(null);
            }
            setIsRetailersLoading(false);
        }
    }, [retailers, isLoading, data, error]);

    return { isRetailersLoading, retailers, retailersError };

};

export default useFetchRetailers;
