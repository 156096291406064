import NavigationButton from "./NavigationButton";
import PatientsIcon from "../../icons/PatientsIcon";
import SettingsIcon from "../../icons/SettingsIcon";
import TrainingsResultIcon from "../../icons/TrainingsResultIcon";
import theme from "../../assets/theme";
import ResultAssignmentIcon from "../../icons/ResultAssignmentIcon";
import useFetchUnassignedSessions from "../../hooks/useFetchUnassignedSessions";
import { t } from "i18next";

export const PatientsNavigation = () => {
    const { isUnassignedSessionsLoading, unassignedSessions } = useFetchUnassignedSessions();
    if (isUnassignedSessionsLoading) {
        return <></>;
    }

    return (
        <div style={{ height: '70px', lineHeight: '70px', backgroundColor: 'white' }}>
            <div style={{ display: 'inline-flex', height: '70px', lineHeight: '70px', backgroundColor: theme.palette.inactive.main }}>
                <NavigationButton returnButton={true} to="/patients" label={t('navigation-button-patients')} Icon={PatientsIcon} />

                <div style={{ display: 'inline-flex', minWidth: '20px', backgroundColor: theme.palette.inactive.main }}>&nbsp;</div>
                <NavigationButton firstButton={true} to="/patients/info" underlined={true} label={t('navigation-button-patient-info')} Icon={PatientsIcon} />
                <NavigationButton to="/patients/settings" underlined={true} label={t('navigation-button-trainig-settings')} Icon={SettingsIcon} />
                <NavigationButton to="/patients/assignment" underlined={true} label={t('navigation-button-result-assignment')} count={unassignedSessions.length} Icon={ResultAssignmentIcon} />
                <NavigationButton to="/patients/results" underlined={true} label={t('navigation-button-trainig-results')} Icon={TrainingsResultIcon} />
            </div>
        </div>
    );
};
