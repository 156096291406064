import { NavLink, useLocation } from "react-router-dom";
import NavigationBarButton from "./NavigationBarButton";
import { Box, SvgIconProps, Typography, styled } from "@mui/material";
import { t } from "i18next";
import { ArrowLeftIcon } from "@mui/x-date-pickers";
import { ComponentType, useEffect, useState } from "react";
import theme from "../../assets/theme";
import useFetchUnassignedSessions from "../../hooks/useFetchUnassignedSessions";

const NaviagionButton = styled('div')({
    display: 'inline-flex',
    height: '100%',
    backgroundColor: 'white',
});

const NumberElement = styled('div')(({ theme }) => ({
    position: 'relative',
    top: '-8px',
    left: '10px',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '11px',
    height: '18px',
    width: '18px',
    color: 'white',
    backgroundColor: theme.palette.warning.main,
    borderRadius: '9px',

}));

type Props = {
    to: string;
    label: string;
    Icon: ComponentType<SvgIconProps>;
    underlined?: boolean;
    returnButton?: boolean;
    firstButton?: boolean;
};
const ResultAssignmentNavigationButton = ({ firstButton, returnButton, underlined, Icon, to, label }: Props) => {
    const { isUnassignedSessionsLoading, unassignedSessions } = useFetchUnassignedSessions();
    const location = useLocation();
    const isActive = location.pathname === to;
    const [count, setCount] = useState(0);
    useEffect(() => {
        if (!isUnassignedSessionsLoading) {
            setCount(unassignedSessions.length);
        }
    }, [isUnassignedSessionsLoading, unassignedSessions, setCount]);
    if (isUnassignedSessionsLoading) {
        return <></>;
    }

    const renderNavigationButton = () => {
        if (returnButton) {
            return <Box style={{ display: 'inline-flex', height: '100%', borderBottomRightRadius: '10px', backgroundColor: 'white' }}>
                <NavLink to={to} >
                    <NavigationBarButton>
                        <ArrowLeftIcon sx={{ marginRight: '5px' }} />
                        <Icon sx={{ marginRight: '10px' }} />
                        <Typography sx={{ textAlign: 'left', fontSize: 'inherit' }} fontWeight="bold" >{t(label)}</Typography>
                    </NavigationBarButton >
                </NavLink>
            </Box>;
        }

        if (isActive) {
            if (underlined) {
                return <NaviagionButton style={{ borderBottom: `solid 4px ${theme.palette.active.main}`, borderBottomLeftRadius: firstButton ? '10px' : 0 }}>
                    <NavLink to={to}>
                        <NavigationBarButton >
                            {count > 0 && <NumberElement>{count.toString()}</NumberElement>}
                            <Icon sx={{ marginRight: '10px' }} />
                            <Typography sx={{ textAlign: 'left', fontSize: 'inherit' }} fontWeight="bold" >{t(label)}</Typography>
                        </NavigationBarButton >
                    </NavLink>
                </NaviagionButton>;

            }
            return <NaviagionButton style={{ borderBottomLeftRadius: firstButton ? '10px' : 0 }}>
                <NavLink to={to} >
                    <NavigationBarButton>
                        {count > 0 && <NumberElement>{count.toString()}</NumberElement>}
                        <Icon sx={{ marginRight: '10px' }} />
                        <Typography sx={{ textAlign: 'left', fontSize: 'inherit' }} fontWeight="bold" >{t(label)}</Typography>
                    </NavigationBarButton >
                </NavLink>
            </NaviagionButton>;
        }


        return <NaviagionButton style={{ borderBottomLeftRadius: firstButton ? '10px' : 0 }}>
            <NavLink to={to}>
                < NavigationBarButton >
                    {count > 0 && <NumberElement>{count.toString()}</NumberElement>}
                    <Icon sx={{ marginRight: '10px' }} />
                    <Typography sx={{ textAlign: 'left', fontSize: 'inherit' }} fontWeight="normal" >{t(label)}</Typography>
                </NavigationBarButton >
            </NavLink>
        </NaviagionButton>;

    };

    return renderNavigationButton();
};

export default ResultAssignmentNavigationButton;
