import { selectCurrentPatientId } from "../../../../store/uiState";
import { useAppSelector } from "../../../../store";
import TrainingsResultsPage from "./TrainingsResultsPage";
import useFetchAssignedSessions from "../../../../hooks/useFetchAssignedSessions";
import SingleColumn from "../../../PageTemplates/SingleColumn";
import NoSessionFoundMessage from "../NoSessionFoundMessage";

const TrainingsResults = () => {
    const patientId = useAppSelector(selectCurrentPatientId);
    const { isAssignedSessionsLoading, assignedSessions } = useFetchAssignedSessions(patientId ?? '');

    if (isAssignedSessionsLoading) {
        return <></>;
    }
    const getTrainingsResultsPage = () => {
        if (patientId && assignedSessions && assignedSessions.length > 0) {
            return <TrainingsResultsPage assignedSessions={assignedSessions} patientId={patientId} />;
        }
        return <SingleColumn component={<NoSessionFoundMessage showBorder={false} />} />;
    };

    return (getTrainingsResultsPage());
};

export default TrainingsResults;
