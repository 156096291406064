import { Popover, Stack } from "@mui/material";
import ActivateIcon from "../../../icons/ActivateIcon";
import DeactivateIcon from "../../../icons/DeactivateIcon";
import TrashIcon from "../../../icons/TrashIcon";
import UndeleteIcon from "../../../icons/UndeleteIcon";
import ConfirmDialog from "../ConfirmDialog/ConfirmDialog";
import { PopoverEntry } from "./DetailsHeader";
import EditIcon from "../../../icons/EditIcon";
import { smallFont } from "../../../assets/theme";
import { t } from "i18next";
import { ReactNode } from "react";

type Props = {
    open: boolean;
    hideMenu: () => void;
    anchorEl: HTMLButtonElement | undefined;
    editEntry?: () => void;
    switchActive?: () => void;
    deleteEntry?: () => void;
    isDeleted: boolean;
    isActive: boolean;
    setShowConfirmDialog: (flag: boolean) => void;
    showConfirmDialog: boolean;
    showConfirm: () => void;
    handleDelete: () => void;
    deleteEntryMessage: ReactNode;
    undeleteEntryMessage: ReactNode;
};

const ThreeDotsMenu = ({ open, hideMenu, anchorEl, editEntry, isActive, switchActive, isDeleted, deleteEntry, showConfirm, showConfirmDialog, setShowConfirmDialog, handleDelete, deleteEntryMessage, undeleteEntryMessage }: Props) => {
    return (
        <>
            <Popover
                open={open}
                onClose={hideMenu}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                sx={{
                    borderRadius: '10px',
                    userSelect: 'none'
                }}
            >
                <Stack direction="column" spacing={2} padding="10px">
                    {editEntry && <PopoverEntry onClick={editEntry}><EditIcon sx={{ fontSize: smallFont, marginRight: '8px' }} />{t('drop-down-edit')}</PopoverEntry>}
                    {isActive && switchActive && <PopoverEntry onClick={switchActive}><DeactivateIcon sx={{ fontSize: smallFont, marginRight: '8px' }} />{t('deactivate-label')}</PopoverEntry>}
                    {!isActive && switchActive && <PopoverEntry onClick={switchActive}><ActivateIcon sx={{ fontSize: smallFont, marginRight: '8px' }} />{t('activate-label')}</PopoverEntry>}
                    {!isDeleted && deleteEntry && <PopoverEntry onClick={showConfirm}><TrashIcon sx={{ fontSize: smallFont, marginRight: '8px' }} />{t('drop-down-delete')}</PopoverEntry>}
                    {isDeleted && deleteEntry && <PopoverEntry onClick={showConfirm}><UndeleteIcon sx={{ fontSize: smallFont, marginRight: '8px' }} />{t('drop-down-undelete')}</PopoverEntry>}
                </Stack>
            </Popover>
            {deleteEntry &&
                <ConfirmDialog
                    title={t('delete-dialog-title')}
                    cancelCallback={() => { setShowConfirmDialog(false); }}
                    okCallback={handleDelete}
                    isVisible={showConfirmDialog}
                    message={!isDeleted ? deleteEntryMessage : undeleteEntryMessage}
                />
            }

        </>
    );

};
export default ThreeDotsMenu;
