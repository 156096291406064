import { SetStateAction, useEffect } from "react";
import { enqueueSnackbar } from "notistack";
import { useGetRetailerQuery, useUpdateRetailerMutation } from "../../../api/retailerApi";
import { Pages } from "../../CloudMember/MemberListPanels/listPanelGlobals";
import EditCloudMember from "../../CloudMember/DetailsPanel/EditCloudMember";

const ErrorMessage = (message: any) => {
    return (<div style={{ padding: '5px' }}>{message}</div>);
};

type DetailsProps = {
    setPage: (value: SetStateAction<Pages>) => void;
    retailerId: string;
};

const EditRetailer = ({ retailerId, setPage }: DetailsProps) => {
    const { data, isLoading } = useGetRetailerQuery(retailerId);//, { refetchOnMountOrArgChange: true });
    const [updateRetailer, { error }] = useUpdateRetailerMutation();

    useEffect(() => {
        if (error) {
            enqueueSnackbar(ErrorMessage(error), { variant: 'error' });

        }
    }, [error]);

    if (isLoading) {
        return <div>loading...</div>;
    }

    const update = async (params: any) => {
        updateRetailer(params);
        setPage(Pages.DETAILS);
    };

    return (
        <EditCloudMember update={update} data={data} setPage={setPage} />
    );
};

export default EditRetailer;
