import { t } from "i18next";
import { useAppSelector } from "../store";
import { UserRole, selectUserRole } from "../store/authSlice";

const useGetMemberNames = () => {
    const role = useAppSelector(selectUserRole);

    const getMemberName = () => {
        switch (role) {
            case UserRole.SystemAdmin:
                return t('member-type-admin');
            case UserRole.ManufacturerAdmin:
                return t('member-type-manufacturer');
            case UserRole.RetailerAdmin:
                return t('member-type-retailer');
            case UserRole.ClinicAdmin:
                return t('member-type-clinic');
            default:
                return 'unknown';
        }
    };


    const getInviteMemberName = () => {
        switch (role) {
            case UserRole.RetailerAdmin:
                return t('member-type-manufacturer');
            case UserRole.ClinicAdmin:
                return t('member-type-retailer');
            default:
                return 'unknown';
        }
    };

    const getAcceptMemberName = () => {
        switch (role) {
            case UserRole.ManufacturerAdmin:
                return t('member-type-retailer');
            case UserRole.RetailerAdmin:
                return t('member-type-clinic');
            default:
                return 'unknown';
        }
    };

    const memberName = getMemberName();
    const inviteMemberName = getInviteMemberName();
    const acceptMemberName = getAcceptMemberName();
    return { memberName, inviteMemberName, acceptMemberName };
};

export default useGetMemberNames;
