export interface ConfigState {
    version: string,
    apiBaseUrl: string,
    checkAccessTokenIntervall: number,
    minAccessTokenTimeLeft: number,
    inactivityTimeout: number,
    checkInactivityIntervall: number;
}

export const VERSION = 'A009-325-THERA-cloud-Frontend-CLD_v0.4-20240822T160000-04';
export const API_BASE_URL = window.config.apiBaseUrl;
export const CHECK_ACCESS_TOKEN_INTERVALL = window.config.checkAccessTokenIntervall;
export const MIN_ACCESS_TOKEN_TIME_LEFT = window.config.minAccessTokenTimeLeft;
export const INACTIVITY_TIMEMOUT = window.config.inactivityTimeout;
export const CHECK_INACTIVITY_INTERVALL = window.config.checkInactivityIntervall;

