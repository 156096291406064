import { enqueueSnackbar } from "notistack";
import { ErrorMessage } from "../../DetailsPanel/AddCloudMember";
import { t } from "i18next";
import { useAcceptClinicTokenMutation } from "../../../../api/retailerApi";
import AcceptInvitationDialog from "./AcceptInvitationDialog";
import { useRef } from "react";
import { isEmpty } from "../../../ResultAssignment/fragments/SessionDetailsBox";

type DialogProps = {
    okCallback: (token?: string) => void;
    acceptText?: string;
};
const AcceptClinicInvitation = ({ acceptText, okCallback }: DialogProps) => {
    const fieldRef = useRef<HTMLInputElement>();
    const [acceptToken] = useAcceptClinicTokenMutation();

    const handleOk = (token?: string) => {
        if (token && !isEmpty(token)) {
            acceptToken({ token: token }).then(() => okCallback());
            return;
        }
        enqueueSnackbar(ErrorMessage(t('accept-member-field-error')), { key: 'response-error', preventDuplicate: true, anchorOrigin: { horizontal: 'left', vertical: 'top' }, variant: 'error' });
        fieldRef.current?.focus();
    };

    return (<AcceptInvitationDialog acceptText={acceptText} okCallback={okCallback} handleOk={handleOk} />);
};

export default AcceptClinicInvitation;
