import { useEffect, useState } from "react";
import { Patient } from "../types/types";
import { useGetPatientListQuery } from "../api/patientApi";

const useFetchPatients = () => {
    const { data, isLoading, error } = useGetPatientListQuery(); //undefined, { refetchOnMountOrArgChange: true });
    const [patients, setPatients] = useState<Patient[]>();
    const [isPatientsLoading, setIsPatientsLoading] = useState(false);
    const [patientsError, setPatientsError] = useState<string | null>(null);

    useEffect(() => {
        setIsPatientsLoading(isLoading);
        if (error) {
            setIsPatientsLoading(false);
            setPatientsError(JSON.stringify(error));
        } else if (!isLoading && data) {
            if (JSON.stringify(data) !== JSON.stringify(patients)) {
                setPatients(data);
                setPatientsError(null);
            }
            setIsPatientsLoading(false);
        }
    }, [patients, isLoading, data, error]);

    return { isPatientsLoading, patients, patientsError };

};

export default useFetchPatients;
