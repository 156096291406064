import { Box, Stack, Typography, styled } from "@mui/material";
import ThreeDottMenuIcon from "../../../icons/ThreeDottMenuIcon";
import { ReactNode, useRef, useState } from "react";
import { largeFont } from "../../../assets/theme";
import ThreeDotsMenu from "./ThreeDotsMenu";

const StyledLabel = styled(Typography)({
    fontSize: largeFont,
    fontWeight: 'bold',
    textOverflow: "ellipsis",
    overflow: 'hidden',
    width: 'calc(100% - 20px)',
    whiteSpace: 'nowrap'
});

export const PopoverEntry = styled(Typography)(({ theme }) => ({
    display: 'flex',
    paddingRight: '20px',
    paddingLeft: '3px',
    color: theme.palette.primary.main,
    textDecoration: 'none',
    cursor: 'pointer',
    alignItems: 'center',
}));


type DetailsHeaderProps = {
    name: string;
    editEntry?: () => void;
    switchActive?: () => void;
    deleteEntry?: () => void;
    deleteEntryMessage?: ReactNode;
    undeleteEntryMessage?: ReactNode;
    isDeleted: boolean;
    isActive: boolean;
    memberType: string;
};

const DetailsHeader = ({ memberType, isDeleted, isActive, editEntry, switchActive, deleteEntry, deleteEntryMessage, undeleteEntryMessage, name }: DetailsHeaderProps) => {
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>();
    const open = Boolean(anchorEl);
    const dialogRef = useRef();
    const hideThreeDotMenu = !editEntry && !switchActive && !deleteEntry;


    const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (open) {
            return hideMenu();
        }
        setAnchorEl(dialogRef.current);
        setShowConfirmDialog(false);
    };

    const hideMenu = () => {
        setAnchorEl(undefined);
    };

    const showConfirm = () => {
        hideMenu();
        setShowConfirmDialog(true);
    };

    const handleDelete = () => {
        setShowConfirmDialog(false);
        if (deleteEntry) {
            deleteEntry();
        }
    };

    return (
        <Stack direction="row" style={{ width: 'calc(100% - 90px)' }}>
            <Stack direction="column" sx={{ width: '100%' }}>
                <StyledLabel pt="10px">{name}</StyledLabel>
                <StyledLabel sx={{ fontWeight: 'normal' }}>{memberType}</StyledLabel>
            </Stack>
            {!hideThreeDotMenu &&
                <Stack alignItems="end" style={{ cursor: 'pointer' }}>
                    <Box ref={dialogRef} onClick={handleClick} style={{ display: 'flex', cursor: 'pointer' }}>
                        <ThreeDottMenuIcon />
                    </Box>
                    <ThreeDotsMenu
                        open={open}
                        hideMenu={hideMenu}
                        anchorEl={anchorEl}
                        editEntry={editEntry}
                        isActive={isActive}
                        switchActive={switchActive}
                        isDeleted={isDeleted}
                        deleteEntry={deleteEntry}
                        showConfirm={showConfirm}
                        showConfirmDialog={showConfirmDialog}
                        setShowConfirmDialog={setShowConfirmDialog}
                        handleDelete={handleDelete}
                        deleteEntryMessage={deleteEntryMessage}
                        undeleteEntryMessage={undeleteEntryMessage} />
                </Stack>}
        </Stack>
    );
};

export default DetailsHeader;
