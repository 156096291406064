import { memo, useEffect, useState } from 'react';
import TwoColumns from '../PageTemplates/TwoColumns';
import { t } from 'i18next';
import ButtonBar from './fragments/ButtonBar';
import { DeviceMessage } from '../../types/types';
import { useAppDispatch } from '../../store';
import { resetFilter } from '../../store/filterState';
import { resetListState } from '../../store/listStateSlice';
import { useGetDeviceListQuery } from '../../api/deviceApi';
import DeviceList from './fragments/DeviceList';
import LogFiles from './fragments/LogFiles';
import { Box } from '@mui/material';
import { largeFont } from '../../assets/theme';

const MemoList = memo(DeviceList);

const DeviceLog = () => {
    const [deviceId, setDeviceId] = useState("");
    const { isLoading, data } = useGetDeviceListQuery(undefined, { refetchOnMountOrArgChange: true });
    const [scrollPos, setScrollPos] = useState(0);

    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(resetFilter());
        dispatch(resetListState());
    }, [dispatch]);

    useEffect(() => {
        if (!isLoading && data && data.length > 0) {
            if (deviceId && deviceId !== "") {
                const selectedDevice = data.find((entry: DeviceMessage) => entry.id === deviceId);
                if (!selectedDevice && data[0].id !== deviceId) {
                    showDetails(data[0].id, scrollPos);
                }
            } else {
                showDetails(data[0].id, scrollPos);
            }
        }
    }, [isLoading, data, deviceId, scrollPos, dispatch]);

    const getRightComponent = () => {
        if (data && deviceId) {
            const selectedDevice = data.find((entry: DeviceMessage) => entry.id === deviceId);
            if (selectedDevice) {
                return (<LogFiles device={selectedDevice} deviceId={deviceId} />);
            }
            return (
                <Box style={{
                    padding: '20px', height: '100%', fontSize: largeFont, display: 'flex', alignItems: 'center', justifyContent: 'center'
                }}>{t('device-not-found-message')}</Box>

            );
        }
        return (<div style={{ padding: '20px' }}>Default Detailseite</div>);
    };

    if (isLoading) {
        return <div>loading...</div>;
    }

    const showDetails = (id: string, scrollPos: number) => {
        setScrollPos(scrollPos);
        setDeviceId(() => id);
    };

    return (
        <TwoColumns
            leftComponent={<>
                <ButtonBar
                    searchFieldPlaceholder={t('device-searchfield-placeholder')}
                    addEntryLabel={t('device-add-button')}
                    showAddButton={false}
                    addNewEntry={() => { }} />
                <MemoList scrollPos={scrollPos} selectedId={deviceId} showDetails={showDetails} data={data} />
            </>}
            rightComponent={getRightComponent()} />
    );

};

export default DeviceLog;
