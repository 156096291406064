// Need to use the React-specific entry point to allow generating React hooks
import { DeviceLog, DeviceMessage, LyraSignupRequest, LyraUpdateRequest, PinPatientToDeviceRequest } from '../types/types';
import { emptyBaseApi } from './emptyBaseApi';

export const deviceApi = emptyBaseApi.injectEndpoints({
    endpoints: (builder) => ({
        getDeviceList: builder.query<DeviceMessage[], void>({
            query: () => ({
                url: 'device/all',
                method: 'GET',

            }),
            providesTags: ['Device'],
        }),
        fetchDeviceList: builder.mutation<DeviceMessage[], void>({
            query: () => ({
                url: 'device/all',
                method: 'GET',

            }),
        }),
        addLyraDevice: builder.mutation<DeviceMessage, LyraSignupRequest>({
            query: (device) => ({
                url: 'device/signup/lyra',
                method: 'POST',
                body: device,
            }),
            invalidatesTags: ['Clinic', 'Device']
        }),
        getDevice: builder.query<DeviceMessage, string>({
            query: (id) => ({
                url: `device/get?id=${id}`,
                method: 'GET',
            }),
            providesTags: ['Device'],
        }),
        deleteDevice: builder.mutation({
            query: (id) => ({
                url: `device/remove?id=${id}`,
                method: 'GET',
            }),
            invalidatesTags: ['Device']
        }),
        updateLyraDevice: builder.mutation<DeviceMessage, LyraUpdateRequest>({
            query: (device) => ({
                url: 'device/update/lyra',
                method: 'POST',
                body: device,
            }),
            invalidatesTags: ['Device']
        }),
        switchDeviceActive: builder.mutation<DeviceMessage, string>({
            query: (id) => ({
                url: `device/switch_active?id=${id}`,
                method: 'GET',
            }),
            invalidatesTags: ['Device', 'TherapySession']
        }),
        undeleteDevice: builder.mutation({
            query: (id) => ({
                url: `device/undelete?id=${id}`,
                method: 'GET',
            }),
            invalidatesTags: ['Device', 'TherapySession']
        }),
        pinPatientToDevice: builder.mutation<DeviceMessage, PinPatientToDeviceRequest>({
            query: (params) => ({
                url: `device/pin_patient?deviceId=${params.deviceId}&patientId=${params.patientId ? params.patientId : ''}`,
                method: 'GET',
                invalidateTags: ['Device']
            }),
            invalidatesTags: ['Device', 'TherapySession']
        }),
        getDeviceLogs: builder.query<DeviceLog[], string>({
            query: (id) => ({
                url: `device/logs?id=${id}`,
                method: 'GET',
            }),
        }),
        downloadDeviceLog: builder.mutation<any, string>({
            query: (id) => ({
                url: `device/download_log?id=${id}`,
                method: 'GET',
                responseHandler: async (response) => {
                    const filename = response.headers.get('filename') ?? 'device-log.csv';
                    const data = await response.blob();
                    let link = document.createElement('a');
                    link.href = window.URL.createObjectURL(data);
                    link.download = filename;
                    document.body.appendChild(link);
                    link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
                    link.remove();
                    window.URL.revokeObjectURL(link.href);
                },
                cache: "no-cache",
            })
        }),
    }),
    overrideExisting: true,
});

export const { usePinPatientToDeviceMutation, useDownloadDeviceLogMutation, useGetDeviceLogsQuery, useFetchDeviceListMutation, useAddLyraDeviceMutation, useDeleteDeviceMutation, useGetDeviceListQuery, useGetDeviceQuery,
    useSwitchDeviceActiveMutation, useUndeleteDeviceMutation, useUpdateLyraDeviceMutation } = deviceApi;;
