import { Box, Button, Popover, Stack, Typography, styled } from "@mui/material";
import { t } from "i18next";
import { useRef, useState } from "react";
import TwoArrowsUpIcon from "../../../../icons/TwoArrowsUpIcon";
import { largeFont, tinyFont } from "../../../../assets/theme";
import TwoArrowsDownIcon from "../../../../icons/TwoArrowsDownIcon";
import { DeviceMessage, MappedTherapySessionData } from "../../../../types/types";
import SessionsFilterCheckbox from "./SessionsFilterCheckbox";
import FilterIcon from "../../../../icons/FilterIcon";
import FilterCount from "./FilterCount";

const ArrowUp = styled(TwoArrowsUpIcon)(({ theme }) => ({
    fontSize: tinyFont,
    '& path': {
        fill: theme.palette.primary.main
    }
}));

const ArrowDown = styled(TwoArrowsDownIcon)(({ theme }) => ({
    fontSize: tinyFont,
    '& path': {
        fill: theme.palette.primary.main
    }
}));

const Label = styled('div')({
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    paddingRight: '40px'
});

const StyledFilterButton = styled(Button)(({ theme }) => ({
    minWidth: 'min-content',
    borderColor: theme.palette.inactive.main,
    backgroundColor: theme.palette.inactive.main,
    color: theme.palette.primary.main,
    fontSize: largeFont,
    whiteSpace: 'nowrap',
    "&:hover": {
        backgroundColor: theme.palette.inactive.main,
    },
    "&:active": {
        backgroundColor: theme.palette.inactive.main,
    },
    '& .MuiButton-startIcon': {
        '& > *:nth-of-type(1)': {
            fontSize: largeFont,
        },
    },
}));

type Props = {
    assignedSessions: MappedTherapySessionData[];
    devices: DeviceMessage[];
};

const SessionsFilterButton = ({ devices, assignedSessions }: Props) => {
    const ref = useRef();
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>();
    const open = Boolean(anchorEl);
    const sessions = assignedSessions.map((session) => session.serial);
    const unique = [...new Set(sessions)];

    const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (open) {
            return;
        }
        setAnchorEl(ref.current);
    };

    const hideMenu = () => {
        setAnchorEl(undefined);
    };

    const getCheckboxLabel = (serial: string) => {
        const name = devices.find(device => device.serial === serial)?.name;
        return name ?? '-';
    };

    const renderDropDown = () => {
        return (
            <Popover
                open={open}
                onClose={hideMenu}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                sx={{
                    userSelect: 'none'
                }}
            >
                <Stack direction="column">
                    <Stack direction="row" key="all">
                        <SessionsFilterCheckbox assignedSessions={assignedSessions} deviceSerial="all" />
                        <Label>Alle</Label>
                    </Stack>
                    {unique.map(serial => {
                        return (
                            <Stack direction="row" key={serial} sx={{ borderTop: `solid 1px rgba(0, 0, 0, 0.12)` }}>
                                <SessionsFilterCheckbox assignedSessions={assignedSessions} deviceSerial={serial} />
                                <Label>{getCheckboxLabel(serial)}</Label>
                            </Stack>);
                    })}

                </Stack>
            </Popover >
        );
    };

    return (
        <Box ref={ref} onClick={handleClick}>
            <StyledFilterButton variant="contained" disableRipple={true}>
                <FilterIcon sx={{ marginLeft: '0px', marginRight: '10px' }} />
                <Typography sx={{ textAlign: 'left', paddingRight: '10px' }} fontSize="22px">{t('filter-button')}</Typography>
                <FilterCount />
                {open ? <ArrowUp /> : <ArrowDown />}
            </StyledFilterButton>
            {renderDropDown()}
        </Box >
    );
};

export default SessionsFilterButton;
