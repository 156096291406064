import { createTheme } from '@mui/material';

// Augment the palette to include an ochre color
declare module '@mui/material/styles' {
  interface Palette {
    lightgreen: Palette['primary'];
    darkgreen: Palette['primary'];
    linkgray: Palette['primary'];
    inactive: Palette['primary'];
    active: Palette['primary'];
    deleted: Palette['primary'];
    attention: Palette['primary'];
  }

  interface PaletteOptions {
    lightgreen?: PaletteOptions['primary'];
    darkgreen?: PaletteOptions['primary'];
    linkgray?: Palette['primary'];
    inactive?: PaletteOptions['primary'];
    active?: PaletteOptions['primary'];
    deleted?: PaletteOptions['primary'];
    attention?: PaletteOptions['primary'];
  }
}

// Update the Button's color options to include an ochre option
declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    lightgreen: true;
    darkgreen: true;
    linkgray: true;
    inactive: true;
    active: true;
    deleted: true;
    attention: true;
  }
}

export const tinyFont = '8pt';
export const smallFont = '10pt';
export const mediumFont = '12pt';
export const largeFont = '14pt';

export const avatarColors = [
  { color: '#F9B000', background: '#F9E9CE' },
  { color: '#F29177', background: '#fee9e3' },
  { color: '#7D6A89', background: '#deced9' },
  { color: '#227075', background: '#d2e2e3' },
];

export const pieChartColors = [
  '#227075',
  '#1BA77E',
  '#CEE9DC',
  '#6F8DA7',
  '#86C7AC',
  '#646464',
  '#EBEBEB',
  '#173E50',
  '#AFAFAF',
  '#D2E2E3',
];

const theme = createTheme({
  typography: {
    fontFamily: 'Heebo, Aral, Helvetica',
    button: {
      textTransform: 'none'
    }
  },
  palette: {
    text: {
      primary: '#646464',
    },

    linkgray: {
      main: '#afafaf',
      dark: '#afafaf',
      light: '#afafaf',
      contrastText: 'black',
    },

    lightgreen: {
      main: '#CEE9DC',
      dark: '#CEE9DC',
      light: '#86C7AC',
      contrastText: '#646464',
    },

    darkgreen: {
      main: '#1ba77e',
      dark: '#1ba77e',
      contrastText: 'white',
    },

    primary: {
      main: '#646464',
      dark: '#646464',
      light: '#707070',
      contrastText: 'white',
    },

    secondary: {
      main: '#fff',
      dark: '#fff',
      contrastText: '#707070',
    },

    active: {
      main: '#1ba77e',
      contrastText: '#1ba77e',
    },

    deleted: {
      main: '#f29177',
      contrastText: '#f29177',
    },

    inactive: {
      main: '#ebebeb',
      dark: '#ebebeb',
      contrastText: '#7d6a89',
    },

    info: {
      main: '#f9b000',
      dark: '#f9b000',
      contrastText: 'white',
    },

    warning: {
      main: '#f29177',
      dark: '#f29177',
      contrastText: 'white',
    },

    attention: {
      main: '#F9B000',
      dark: '#F9B000',
      contrastText: '#646464',
    },

    success: {
      main: '#1AA77E',
      dark: '#1AA77E',
      contrastText: 'white',
    },

  },
  components: {
    MuiButton: {
      defaultProps: {
        variant: 'outlined',
        disableElevation: true,
        disableFocusRipple: true,
      },
      styleOverrides: {
        root: {
          height: '1rem',
          borderRadius: '5px',
          textTransform: 'none',
        },
        sizeMedium: {
          fontSize: '1rem',
          padding: '1.5rem',
        },
      }
    },
  }
});

export default theme;
