import { styled } from '@mui/material';
import { ReactNode } from 'react';
import theme from '../../assets/theme';

const ContentGrid = styled('div')({
    display: 'flex',
    height: 'calc(100% - 150px)',
    padding: '20px',
    backgroundColor: theme.palette.inactive.main
});


const LeftGrid = styled('div', {
    shouldForwardProp: (prop) => !['width'].includes(prop as string),
})<{ width: string; }>(({ width }) => ({
    backgroundColor: '#FFF',
    boxShadow: '1px 3px 6px #00000029',
    borderRadius: '10px',
    height: '100%',
    width: width
}));


const RightGrid = styled('div', {
    shouldForwardProp: (prop) => !['width'].includes(prop as string),
})<{ width: string; }>(({ width }) => ({
    backgroundColor: '#FFF',
    boxShadow: '1px 3px 6px #00000029',
    borderRadius: '10px',
    height: '100%',
    marginLeft: '20px',
    width: width,
}));

type TwoColumnsProps = {
    leftWidth?: string;
    rightWidth?: string;
    leftComponent: ReactNode;
    rightComponent: ReactNode;
};

const TwoColumns = ({ leftWidth, rightWidth, leftComponent, rightComponent }: TwoColumnsProps) => {
    return (
        <ContentGrid>
            <LeftGrid width={leftWidth ?? '65%'}>
                {leftComponent}
            </LeftGrid>
            <RightGrid width={rightWidth ?? '35%'}>
                {rightComponent}
            </RightGrid>
        </ContentGrid >
    );
};

export default TwoColumns;
