import { TextField, styled } from "@mui/material";
import { mediumFont, } from "../../assets/theme";

const StyledMultilineField = styled(TextField)(({ theme }) => ({
    padding: 0,
    borderColor: theme.palette.primary.light,
    width: '100%',
    '& .MuiOutlinedInput-root': {
        height: '70pt',
        borderRadius: '5px',
        color: theme.palette.secondary.contrastText,
        fontSize: mediumFont,
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.primary.light,
            borderWidth: '1px'
        },
        '& .Mui-focused fieldset': {
            borderColor: theme.palette.primary.light,
            borderWidth: '1px'
        },
    },
    '& .MuiInputBase-root.Mui-disabled': {
        "& .MuiInputBase-input.Mui-disabled": {
            WebkitTextFillColor: theme.palette.secondary.contrastText,
        },
        backgroundColor: 'white',
    },
}));

export default StyledMultilineField;
