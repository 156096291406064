import { useGetManufacturerQuery, useUpdateManufacturerMutation } from "../../../api/manufacturerApi";
import { SetStateAction, useEffect } from "react";
import { enqueueSnackbar } from "notistack";
import { Pages } from "../../CloudMember/MemberListPanels/listPanelGlobals";
import EditCloudMember from "../../CloudMember/DetailsPanel/EditCloudMember";

const ErrorMessage = (message: any) => {
    return (<div style={{ padding: '5px' }}>{message}</div>);
};

type DetailsProps = {
    setPage: (value: SetStateAction<Pages>) => void;
    manufacturerId: string;
};

const EditManufacturer = ({ manufacturerId, setPage }: DetailsProps) => {
    const { data, isLoading } = useGetManufacturerQuery(manufacturerId);//, { refetchOnMountOrArgChange: true });
    const [updateManufacturer, { error }] = useUpdateManufacturerMutation();

    useEffect(() => {
        if (error) {
            enqueueSnackbar(ErrorMessage(error), { variant: 'error' });

        }
    }, [error]);

    if (isLoading) {
        return <div>loading...</div>;
    }

    const update = async (params: any) => {
        updateManufacturer(params);
        setPage(Pages.DETAILS);
    };

    return (
        <EditCloudMember update={update} data={data} setPage={setPage} />
    );
};

export default EditManufacturer;
