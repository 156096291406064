// Need to use the React-specific entry point to allow generating React hooks
import { NewPatientRequest, Patient, ResponseMessage, SwitchPatientActiveRequest, UpdatePinnedRequest, UserPatientMapping } from '../types/types';
import { emptyBaseApi } from './emptyBaseApi';

export const patientApi = emptyBaseApi.injectEndpoints({
    endpoints: (builder) => ({
        addPatient: builder.mutation<string, NewPatientRequest>({
            query: (patient) => ({
                url: 'patient/add',
                method: 'POST',
                body: patient,
            }),
            invalidatesTags: ['Patient']
        }),
        updatePatient: builder.mutation<string, NewPatientRequest>({
            query: (patient) => ({
                url: 'patient/update',
                method: 'POST',
                body: patient,
            }),
            invalidatesTags: ['Patient']
        }),
        getPatientList: builder.query<Patient[], void>({
            query: () => ({
                url: `patient/list`,
                method: 'GET',
            }),
            providesTags: ['Patient'],
        }),
        updatePinnedPatients: builder.mutation<UserPatientMapping[], UpdatePinnedRequest>({
            query: (param) => ({
                url: `patient/update_pinned`,
                method: 'POST',
                body: param
            }),
            invalidatesTags: ['Patient'],

        }),
        getPatient: builder.query<Patient, string>({
            query: (id) => ({
                url: `patient/get?patientId=${id}`,
                method: 'GET',
            }),
            providesTags: ['Patient'],
        }),
        deletePatient: builder.mutation<ResponseMessage, string>({
            query: (id) => ({
                url: `patient/delete?patientId=${id}`,
                method: 'GET',
            }),
            invalidatesTags: ['Patient'],
        }),
        switchPatientActive: builder.mutation<Patient, SwitchPatientActiveRequest>({
            query: (param) => ({
                url: `patient/set_active`,
                method: 'POST',
                body: param
            }),
            invalidatesTags: ['Patient'],
        }),
    }),
    overrideExisting: true,
});

export const { useSwitchPatientActiveMutation, useDeletePatientMutation, useUpdatePatientMutation, useUpdatePinnedPatientsMutation, useGetPatientQuery, useAddPatientMutation, useGetPatientListQuery } = patientApi;
