import { createAsyncThunk } from '@reduxjs/toolkit';
import { API_BASE_URL } from '../config/constanst';
import { LogonUser } from '../types/types';

export const signIn = createAsyncThunk(
    'auth/signin',
    async (user: LogonUser) => {
        const response = await fetch(new URL(`${API_BASE_URL}auth/signin`).toString(), {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: `{"email":"${user.email}","password":"${user.password}"}`,
        });
        return await response.json();
    }
);
