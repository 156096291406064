import { Button, Dialog, Stack, styled } from "@mui/material";
import SearchField from "./SearchField";
import FilterButton from "./FilterButton";
import { useState } from "react";
import ChooseAddActionDialog from "../CloudInvitation/ChooseAddActionDialog/ChooseAddActionDialog";
import InviteRetailerDialog from "../CloudInvitation/InviteMemberDialog.tsx/InviteRetailerDialog";
import InviteManufacturerDialog from "../CloudInvitation/InviteMemberDialog.tsx/InviteManufacturerDialog";
import { useAppDispatch, useAppSelector } from "../../../store";
import { selectIsClinicAdmin, selectIsManufacturerAdmin, selectIsRetailerAdmin } from "../../../store/authSlice";
import AcceptClinicInvitation from "../CloudInvitation/AcceptInvitationDialog/AcceptClinicInvitation";
import AcceptRetailerInvitation from "../CloudInvitation/AcceptInvitationDialog/AccepRetailerInvitation";
import { selectListFilter, setListFilter } from "../../../store/listStateSlice";
import { largeFont } from "../../../assets/theme";

const AddButton = styled(Button)({
    minWidth: 'min-content',
    fontSize: largeFont,
    whiteSpace: 'nowrap',
});

type ButtonBarProps = {
    addNewEntry: () => void;
    showAddButton: boolean;
    showAcceptButton: boolean;
    showInviteButton: boolean;
    addEntryLabel: string;
    searchFieldPlaceholder: string;
    acceptText?: string,
};

const ListPanelButtonBar = ({ acceptText, searchFieldPlaceholder, addEntryLabel, showAddButton, showAcceptButton, showInviteButton, addNewEntry }: ButtonBarProps) => {
    const [showChooseDialog, setShowChooseDialog] = useState(false);
    const [showInviteDialog, setShowInviteDialog] = useState(false);
    const [showAcceptDialog, setShowAcceptDialog] = useState(false);
    const isRetailerAdmin = useAppSelector(selectIsRetailerAdmin);
    const isManufacturerAdmin = useAppSelector(selectIsManufacturerAdmin);
    const isClinicAdmin = useAppSelector(selectIsClinicAdmin);
    const dispatch = useAppDispatch();
    const filter = useAppSelector(selectListFilter);

    const handleButtonClick = () => {
        if (showAddButton && showAcceptButton) {
            setShowChooseDialog(true);
            return;
        }
        if (showInviteButton) {
            setShowInviteDialog(true);
            return;
        }
        if (showAddButton) {
            addNewEntry();
        }
    };

    const addCallback = () => {
        setShowChooseDialog(false);
        addNewEntry();
    };
    const acceptCallback = () => {
        setShowChooseDialog(false);
        setShowAcceptDialog(true);
    };
    const acceptOkCallback = () => {
        setShowAcceptDialog(false);
    };

    const inviteOkCallback = () => {
        setShowInviteDialog(false);
    };


    const renderInviteDialog = () => {
        if (isClinicAdmin) {
            return <InviteRetailerDialog okCallback={inviteOkCallback} />;
        }
        if (isRetailerAdmin) {
            return <InviteManufacturerDialog okCallback={inviteOkCallback} />;
        }
        return <></>;
    };

    const renderAcceptDialog = () => {
        if (isManufacturerAdmin) {
            return <AcceptRetailerInvitation acceptText={acceptText} okCallback={acceptOkCallback} />;
        }
        if (isRetailerAdmin) {
            return <AcceptClinicInvitation acceptText={acceptText} okCallback={acceptOkCallback} />;
        }
        return <></>;
    };

    return (
        <>
            <Stack paddingLeft="20px" paddingRight="20px" paddingTop="20px" paddingBottom="15px" spacing={2} direction="row" >
                <SearchField placeholder={searchFieldPlaceholder} value={filter} onChange={(value) => { dispatch(setListFilter(value)); }} />
                <FilterButton />
                {(showAddButton || showAcceptButton || showInviteButton) &&
                    <AddButton onClick={handleButtonClick} color="success" variant="contained" disableRipple={true}> {addEntryLabel}</AddButton >
                }
            </Stack >
            {<Dialog open={showChooseDialog}><ChooseAddActionDialog cancelCallback={() => setShowChooseDialog(false)} addCallback={addCallback} acceptCallback={acceptCallback} /></Dialog>}
            {<Dialog open={showInviteDialog}>{renderInviteDialog()}</Dialog>}
            {<Dialog open={showAcceptDialog}>{renderAcceptDialog()}</Dialog >}
        </>
    );
};
export default ListPanelButtonBar;
