import { Checkbox, CheckboxProps } from "@mui/material";
import { RootState, useAppDispatch, useAppSelector } from "../../../store";

type Props = CheckboxProps & {
    selectFilter: (state: RootState) => boolean;
    switchFilter: any;
};

const FilterCheckbox = ({ switchFilter, selectFilter }: Props) => {
    const checked = useAppSelector(selectFilter);
    const dispatch = useAppDispatch();

    return (<Checkbox checked={checked} onChange={() => dispatch(switchFilter())} />);
};

export default FilterCheckbox;
