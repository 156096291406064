import { memo, useEffect, useState } from 'react';
import TwoColumns from '../PageTemplates/TwoColumns';
import { Pages } from '../CloudMember/MemberListPanels/listPanelGlobals';
import { t } from 'i18next';
import ButtonBar from './fragments/ButtonBar';
import { DeviceMessage } from '../../types/types';
import { useAppDispatch, useAppSelector } from '../../store';
import { resetFilter } from '../../store/filterState';
import { selectIsManufacturerAdmin } from '../../store/authSlice';
import { resetListState } from '../../store/listStateSlice';
import AddDevice from './fragments/AddDevice';
import EditDevice from './fragments/EditDevice';
import DeviceList from './fragments/DeviceList';
import { useGetDeviceListQuery } from '../../api/deviceApi';
import DeviceDetails from './fragments/DeviceDetails';

const MemoList = memo(DeviceList);

const Device = () => {
    const [deviceId, setDeviceId] = useState("");
    const [page, setPage] = useState(Pages.DETAILS);
    const { isLoading, data } = useGetDeviceListQuery(undefined, { refetchOnMountOrArgChange: true });
    const [scrollPos, setScrollPos] = useState(0);
    const isManufacturerAdmin = useAppSelector(selectIsManufacturerAdmin);

    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(resetFilter());
        dispatch(resetListState());
    }, [dispatch]);

    useEffect(() => {
        if (!isLoading && data && data.length > 0) {
            if (deviceId && deviceId !== "") {
                const selectedDevice = data.find((entry: DeviceMessage) => entry.id === deviceId);
                if (!selectedDevice && data[0].id !== deviceId) {
                    showDetails(data[0].id, scrollPos);
                }
            } else {
                showDetails(data[0].id, scrollPos);
            }
        }
    }, [isLoading, data, deviceId, scrollPos, dispatch]);

    const getRightComponent = () => {
        switch (page) {
            case Pages.ADD:
                return (<AddDevice setPage={setPage} />);
            case Pages.EDIT:
                return (<EditDevice setPage={setPage} deviceId={deviceId} />);
            case Pages.DETAILS:
                if (deviceId) {
                    return (<DeviceDetails setPage={setPage} deviceId={deviceId} />);

                }
                return (<div style={{ padding: '20px' }}>Default Detailseite</div>);
        }

    };

    if (isLoading) {
        return <div>loading...</div>;
    }

    const showDetails = (id: string, scrollPos: number) => {
        setScrollPos(scrollPos);
        setDeviceId(() => id);
        setPage(Pages.DETAILS);
    };

    const addEntry = () => {
        setPage(Pages.ADD);
    };

    return (
        <TwoColumns leftComponent={
            <>
                <ButtonBar
                    searchFieldPlaceholder={t('device-searchfield-placeholder')}
                    addEntryLabel={t('device-add-button')}
                    showAddButton={isManufacturerAdmin}
                    addNewEntry={addEntry} />
                <MemoList scrollPos={scrollPos} selectedId={deviceId} showDetails={showDetails} data={data} />
            </>}
            rightComponent={getRightComponent()} />
    );

};

export default Device;
