import { Dialog, Grid, TextField, Typography, styled } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import theme, { mediumFont } from "../../../assets/theme";
import { MappedTherapySessionData } from "../../../types/types";
import TheraButton from "../../TheraButtons/TheraButton";
import { getDeviceKey, getTleExerciseDurationClock } from "../../ResultAssignment/fragments/SessionDetailsBox";

export const DisabledMultilineField = styled(TextField)(({ theme }) => ({
    padding: 0,
    borderColor: theme.palette.primary.light,
    width: '100%',
    '& .MuiOutlinedInput-root': {
        borderRadius: '5px',
        color: theme.palette.secondary.contrastText,
        fontSize: mediumFont,
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.primary.light,
            borderWidth: '1px'
        },
        '& .Mui-focused fieldset': {
            borderColor: theme.palette.primary.light,
            borderWidth: '1px'
        },
    },
    '& .MuiInputBase-root.Mui-disabled': {
        "& .MuiInputBase-input.Mui-disabled": {
            WebkitTextFillColor: theme.palette.secondary.contrastText,
        },
        backgroundColor: 'white',
    },
}));

type Props = {
    setShowComment: (flag: boolean) => void;
    session: MappedTherapySessionData;
};

const ShowCommentDialog = ({ session, setShowComment }: Props) => {
    return <Dialog onClose={() => { setShowComment(false); }} open={true}>
        <div style={{ minWidth: '480px', padding: '20px' }}>
            <Grid container direction="column">
                <Grid container item xs={12} sx={{
                    marginBottom: '20px', paddingBottom: '10px', borderBottom: `solid 1px ${theme.palette.linkgray.light}`
                }}>
                    <Grid item xs={11} ><Typography fontWeight="bold">{getDeviceKey(session)}</Typography> {getTleExerciseDurationClock(session)}</Grid>
                    <Grid xs={1} item textAlign={"end"} sx={{ cursor: 'pointer' }} onClick={() => setShowComment(false)}>
                        <CloseIcon stroke={theme.palette.linkgray.main} />
                    </Grid>
                </Grid>
                <Grid item >
                    <DisabledMultilineField
                        fullWidth
                        variant="outlined"
                        multiline
                        maxRows={10}
                        minRows={3}
                        value={session.comment}
                        disabled={true}
                    />
                </Grid>
                <Grid item xs={12} pt="20px">
                    <TheraButton fullWidth onClick={() => setShowComment(false)} color="success" variant="contained">Ok</TheraButton>
                </Grid>

            </Grid>
        </div>
    </Dialog>;
};

export default ShowCommentDialog;
