import ThreeComponents from "../../../PageTemplates/ThreeComponents";
import { addDays } from 'date-fns';
import { useEffect, useState } from "react";
import { MappedTherapySessionData } from "../../../../types/types";
import SessionsPieChart from "./SessionsPieChart";
import SessionsLineChart from "./SessionsLineChart";
import AssignedSessions from "../AssignedSessions";
import PatientAvatar from "../PatientAvatar/PatientAvatar";
import TotalsHeader from "./TotalsHeader";
import DeviceTypeResultsHeader from "./DeviceTypeResultsHeader";
import { getSessionsForType, getUniqueDeviceKeys } from "../../../../hooks/useFetchAssignedSessions";
import { DateRangePicker } from "rsuite";
import { DateRange } from "rsuite/esm/DateRangePicker";
import { iso8601ToDate } from "../../../ResultAssignment/fragments/SessionDetailsBox";
import NoSessionFoundMessage from "../NoSessionFoundMessage";

type Props = {
    patientId: string;
    assignedSessions: MappedTherapySessionData[];
};
const TrainingsResultsPage = ({ assignedSessions, patientId }: Props) => {
    const [currentDeviceKey, setCurrentDeviceKey] = useState<string | undefined>();
    const [dateRange, setDateRange] = useState<[Date, Date]>();
    const [filteredSessions, setFilteredSessions] = useState<MappedTherapySessionData[]>(assignedSessions);

    useEffect(() => {
        const getFilteredSessions = (sessions?: MappedTherapySessionData[]) => {
            if (!sessions) {
                return [];
            }
            if (dateRange) {
                if (dateRange[0].getFullYear() === 1980) {
                    // first + last therapy session
                    if (sessions.length > 1) {
                        return [sessions[0], sessions[sessions.length - 1]];
                    }
                    // only first session
                    return [sessions[0]];
                }
                return sessions.filter((session) => {
                    const start = iso8601ToDate(session.exerciseStartDate);
                    start.setHours(0, 0, 0, 0);
                    dateRange[0].setHours(0, 0, 0, 0);
                    dateRange[1].setHours(0, 0, 0, 0);
                    if (start.getTime() >= dateRange[0].getTime() && start.getTime() <= dateRange[1].getTime()) {
                        return session;
                    }
                    return undefined;
                });
            }
            return sessions;
        };
        setFilteredSessions(getFilteredSessions(assignedSessions));
    }, [dateRange, assignedSessions]);

    const renderValue = () => {
        if (dateRange?.[1].getFullYear() === 1980) {
            return 'erste + letzte Trainingseinheit';
        }
        return '';
    };

    const selectDateRange = (e: DateRange | null) => {
        if (e) {
            setDateRange(() => [e[0], e[1]]);
            return;
        }
        setDateRange(() => undefined);
    };

    const getLeftComponent = () => {
        const sessions = currentDeviceKey ? getSessionsForType(filteredSessions, currentDeviceKey) : filteredSessions;
        return (
            <div style={{ display: 'flex', flexDirection: 'column', height: 'calc(100% - 10px)' }}>
                <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                    <PatientAvatar patientId={patientId} />
                </div>
                <div style={{ display: 'inline-block', width: 'calc(100% - 20px)', paddingLeft: '20px' }}><hr />
                    <DateRangePicker
                        style={{ paddingBottom: '10px', width: '100%' }}
                        onChange={selectDateRange}
                        showOneCalendar
                        character=" - "
                        size="lg"
                        editable={false}
                        format="dd.MM.yyyy"
                        placeholder="Zeitraum auswählen"
                        renderValue={() => { return renderValue(); }}
                        ranges={[
                            {
                                label: 'Erste + Letzte',
                                value: [new Date('1980-01-01'), new Date('1980-01-01')],
                                placement: 'left'
                            },
                            {
                                label: 'Letzen 7 Tage',
                                value: [addDays(new Date(), -7), new Date()],
                                placement: 'left'
                            },
                            {
                                label: 'Letzen 14 Tage',
                                value: [addDays(new Date(), -14), new Date()],
                                placement: 'left'
                            },
                        ]} />
                </div>
                <AssignedSessions assignedSessions={sessions} />
            </div >
        );
    };

    const getUniqueKeys = () => {
        const unique = getUniqueDeviceKeys(filteredSessions);
        return unique;
    };

    const getRightBottomComponent = () => {
        if (getUniqueKeys().length === 0) {
            return <NoSessionFoundMessage />;
        }
        if (getUniqueKeys().length === 1 || currentDeviceKey) {
            const sessions = currentDeviceKey ? getSessionsForType(filteredSessions, currentDeviceKey) : filteredSessions;
            return <SessionsLineChart menuHeight={getUniqueKeys().length > 1 ? 470 : 400} assignedSessions={sessions} />;
        }
        return <SessionsPieChart assignedSessions={filteredSessions} />;
    };


    return (
        <ThreeComponents leftWidth="33%" rightWidth="66%" menuHeight={getUniqueKeys().length === 1 ? 80 : 150}
            leftComponent={getLeftComponent()}
            rightTopComponent={getUniqueKeys().length === 1 ? <DeviceTypeResultsHeader assignedSessions={filteredSessions} /> :
                <TotalsHeader setCurrentDeviceKey={setCurrentDeviceKey} assignedSessions={filteredSessions} patientId={patientId} />}
            rightBottomComponent={getRightBottomComponent()}
        />
    );

};

export default TrainingsResultsPage;
