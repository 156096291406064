import { useEffect, useRef } from "react";
import { Grid, Stack, Table, TableBody, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { CloudMember, DeviceMessage } from "../../../types/types";
import TwoArrowsUpIcon from "../../../icons/TwoArrowsUpIcon";
import TwoArrowsDownIcon from "../../../icons/TwoArrowsDownIcon";
import { t } from "i18next";
import theme, { largeFont, mediumFont, tinyFont } from "../../../assets/theme";
import { useAppDispatch, useAppSelector } from "../../../store";
import { selectActiveFilter, selectDeletedFilter, selectInactiveFilter } from "../../../store/filterState";
import { selectListField, selectListFilter, selectListOrder, setListOrderState } from "../../../store/listStateSlice";
import { selectIsManufacturerAdmin, selectIsSystemaAdmin, selectIsRetailerAdmin } from "../../../store/authSlice";
import TrashIcon from "../../../icons/TrashIcon";
import DeviceAvatar from "../../Avatar/DeviceAvatar";
import StyledTableRow from "../../StyledTable/StyledTableRow";
import { ColumnLabel, DataTableCell, HeaderTableCell } from "../../StyledTable/StyledTableElements";
import { getActiveStatus } from "../../CloudMember/MemberListPanels/CloudMemberList";

const getManufacturerName = (data: DeviceMessage) => {
    if (data.manufacturerName) {
        return data.manufacturerName;
    }
    return '';
};

const getRetailerName = (data: DeviceMessage) => {
    if (data.retailerName) {
        return data.retailerName;
    }
    return '';
};

const getClinicName = (data: DeviceMessage) => {
    if (data.clinicName) {
        return data.clinicName;
    }
    return '';
};

const getActive = (data: DeviceMessage) => {
    if (data.deleted) {
        return '2';
    }
    if (data.active) {
        return '0';
    }

    return '1';
};

const getActiveOrder = (order: number, a: DeviceMessage, b: DeviceMessage) => {
    if (getActive(a) > getActive(b)) {
        return order;
    }
    if (getActive(a) < getActive(b)) {
        return -order;
    }
    return 0;
};

const getManufacturerOrder = (order: number, a: DeviceMessage, b: DeviceMessage) => {
    if (getManufacturerName(a) > getManufacturerName(b)) {
        return order;
    }
    if (getManufacturerName(a) < getManufacturerName(b)) {
        return -order;
    }
    return 0;
};

const getRetailerOrder = (order: number, a: DeviceMessage, b: DeviceMessage) => {
    if (getRetailerName(a) > getRetailerName(b)) {
        return order;
    }
    if (getRetailerName(a) < getRetailerName(b)) {
        return -order;
    }
    return 0;
};

const getClinicOrder = (order: number, a: DeviceMessage, b: DeviceMessage) => {
    if (getClinicName(a) > getClinicName(b)) {
        return order;
    }
    if (getClinicName(a) < getClinicName(b)) {
        return -order;
    }
    return 0;
};

const sortData = (sortField: string, sortOrder: string, data: DeviceMessage[], retailerList?: CloudMember[]) => {
    if (!data) {
        return [];
    }
    return data.slice().sort((a, b) => {
        const order = sortOrder === "up" ? 1 : -1;
        switch (sortField) {
            case 'active':
                return getActiveOrder(order, a, b);
            case 'manufacturer':
                return getManufacturerOrder(order, a, b);
            case 'retailer':
                return getRetailerOrder(order, a, b);
            case 'clinic':
                return getClinicOrder(order, a, b);
            case 'serial':
                if (a.serial > b.serial) {
                    return order;
                }
                if (a.serial < b.serial) {
                    return -order;
                }
                break;
            default:
                if (a.name > b.name) {
                    return order;
                }
                if (a.name < b.name) {
                    return -order;
                }
        }

        return 0;
    });
};


type ListProps = {
    showDetails: (id: string, scrollPos: number) => void;
    selectedId?: string;
    data?: DeviceMessage[];
    scrollPos: number;
};

const DeviceList = ({ scrollPos, data, selectedId, showDetails }: ListProps) => {
    const dispatch = useAppDispatch();
    const filter = useAppSelector(selectListFilter);
    const sortOrder = useAppSelector(selectListOrder);
    const sortField = useAppSelector(selectListField);
    const ref = useRef<HTMLTableElement>(null);
    const tableRef = useRef<HTMLTableElement>(null);
    const active = useAppSelector(selectActiveFilter);
    const inactive = useAppSelector(selectInactiveFilter);
    const deleted = useAppSelector(selectDeletedFilter);
    const isSystemAdmin = useAppSelector(selectIsSystemaAdmin);
    const isManufacturerAdmin = useAppSelector(selectIsManufacturerAdmin);
    const isRetailerAdmin = useAppSelector(selectIsRetailerAdmin);
    const isClinicMember = !(isSystemAdmin || isManufacturerAdmin || isRetailerAdmin);

    useEffect(() => {
        if (ref.current?.parentElement) {
            ref.current.parentElement.scrollTo({ top: scrollPos });
        }
    }, [ref, scrollPos]);

    const clickInRow = ((event: React.MouseEvent<HTMLTableRowElement, MouseEvent>, id: string) => {
        if (selectedId !== id) {
            if (tableRef.current) {
                showDetails(id, tableRef.current?.scrollTop);
            } else {
                showDetails(id, 0);
            }
        }
    });

    const getDeletedColumn = (member: DeviceMessage) => {
        if (isSystemAdmin) {
            if (member.deleted) {
                return <DataTableCell sx={{ width: '20px' }}><TrashIcon sx={{ fontSize: mediumFont }} /></DataTableCell>;

            }
            return <DataTableCell sx={{ width: '20px' }}></DataTableCell>;
        }
    };


    const isInFilter = (member: DeviceMessage) => {
        if (isSystemAdmin) {
            if (member.name.toLowerCase().indexOf(filter.toLowerCase()) >= 0
                || member.serial.toLowerCase().indexOf(filter.toLowerCase()) >= 0
                || (member.manufacturerName ?? '').toLowerCase().indexOf(filter.toLowerCase()) >= 0) {
                return true;
            }
            return false;
        }
        if (isClinicMember || isManufacturerAdmin) {
            if (member.name.toLowerCase().indexOf(filter.toLowerCase()) >= 0
                || member.serial.toLowerCase().indexOf(filter.toLowerCase()) >= 0
                || (member.retailerName ?? '').toLowerCase().indexOf(filter.toLowerCase()) >= 0) {
                return true;
            }
            return false;
        }

        if (member.name.toLowerCase().indexOf(filter.toLowerCase()) >= 0
            || member.serial.toLowerCase().indexOf(filter.toLowerCase()) >= 0
            || (member.clinicName ?? '').toLowerCase().indexOf(filter.toLowerCase()) >= 0) {
            return true;

        }
        return false;
    };

    const getFilteredList = (list?: DeviceMessage[]) => {
        if (list) {
            const filteredList = list.filter((entry) => isInFilter(entry))
                .filter((entry) => (!active && !inactive) || (active && entry.active) || (inactive && !entry.active))
                .filter((entry) => !deleted || (deleted && entry.deleted));
            const mappedList = filteredList.map((member: DeviceMessage) => {
                return (
                    <StyledTableRow
                        selected={selectedId === member.id}
                        key={member.id} sx={{ height: '80px', cursor: 'pointer' }} hover onClick={(event) => clickInRow(event, member.id)}
                    >
                        <DataTableCell sx={{ width: '20px' }}></DataTableCell>
                        <DataTableCell>
                            <div style={{ display: 'flex', alignItems: 'center' }}><DeviceAvatar typ={`${member.type}`.trim()} size="small" />
                                <Typography fontSize={largeFont} pl={'10px'}>{member.name ?? '-'}</Typography>
                            </div>
                        </DataTableCell>

                        <DataTableCell>
                            <Typography fontSize={largeFont} pl={'10px'}>{member.serial}</Typography>
                        </DataTableCell>

                        {(isSystemAdmin) &&
                            <DataTableCell sx={{ width: '220px' }}>
                                <Typography fontSize={largeFont} pl={'10px'}>
                                    {member.manufacturerName}
                                </Typography>
                            </DataTableCell>
                        }
                        {(isClinicMember || isManufacturerAdmin) &&
                            <DataTableCell sx={{ width: '220px' }}>
                                <Typography fontSize={largeFont} pl={'10px'}>
                                    {member.retailerName}
                                </Typography>
                            </DataTableCell>
                        }
                        {(isRetailerAdmin) &&
                            <DataTableCell sx={{ width: '220px' }}>
                                <Typography fontSize={largeFont} pl={'10px'}>
                                    {member.clinicName}
                                </Typography>
                            </DataTableCell>
                        }

                        <DataTableCell sx={{ alignItems: 'center', height: '80px', justifyContent: 'center', display: 'flex', userSelect: 'none' }}>{getActiveStatus(member)}</DataTableCell>
                        {getDeletedColumn(member)}

                        <DataTableCell sx={{ width: '20px' }}></DataTableCell>
                    </StyledTableRow>
                );
            });
            return mappedList;
        }
    };

    const renderList = () => {
        let list = data;
        if (data && sortField) {
            switch (sortOrder) {
                case "up":
                    list = sortData(sortField, "up", data);
                    break;
                case "down":
                    list = sortData(sortField, "down", data);
                    break;
            }
        }
        const result = getFilteredList(list);
        return result;
    };

    const getOpacity = (field: string, order: string) => {
        if (field === sortField && sortOrder === order) {
            return 1;
        }
        return 0.3;
    };

    const toggleColumnSearch = (name: string) => {
        const newOrder = sortOrder === "up" ? "down" : "up";
        dispatch(setListOrderState({ order: newOrder, field: name }));
    };

    const getSortedColumnHeader = (label: string, column: string) => {
        return (<Grid container alignItems="center">
            <Grid>
                <ColumnLabel onClick={() => { toggleColumnSearch(column); }} >{label}</ColumnLabel>
            </Grid>
            <Grid>
                <Stack spacing={.2} pl={2} direction="column">
                    <TwoArrowsUpIcon onClick={() => { toggleColumnSearch(column); }} sx={{ fontSize: tinyFont, opacity: getOpacity(column, "up"), cursor: 'pointer', color: theme.palette.primary.light }} />
                    <TwoArrowsDownIcon onClick={() => { toggleColumnSearch(column); }} sx={{ fontSize: tinyFont, opacity: getOpacity(column, "down"), cursor: 'pointer', color: theme.palette.primary.light }} />
                </Stack>
            </Grid>
        </Grid>
        );
    };

    const getColumnWidh = () => {
        if (isSystemAdmin) {
            return '28%';
        }
        return '30%';
    };

    return (
        <TableContainer ref={tableRef} sx={{ height: 'calc(100% - 150px)' }}>
            <Table ref={ref} stickyHeader>
                <TableHead>
                    <TableRow key="table-header" sx={{ height: '80px' }}>
                        <HeaderTableCell sx={{ minWidth: '20px', width: '20px' }}></HeaderTableCell>
                        <HeaderTableCell sx={{ width: getColumnWidh() }}>
                            {getSortedColumnHeader(t('device-list-name-header'), "name")}
                        </HeaderTableCell>
                        <HeaderTableCell sx={{ width: getColumnWidh() }}>
                            {getSortedColumnHeader(t('device-list-serial-header'), "serial")}
                        </HeaderTableCell>

                        {(isSystemAdmin) && <HeaderTableCell sx={{ width: getColumnWidh() }}>
                            {getSortedColumnHeader(t('device-list-manufacturer-header'), "manufacturer")}
                        </HeaderTableCell>}
                        {(isClinicMember || isManufacturerAdmin) && <HeaderTableCell sx={{ width: getColumnWidh() }}>
                            {getSortedColumnHeader(t('device-list-retailer-header'), "retailer")}
                        </HeaderTableCell>}
                        {(isRetailerAdmin) && <HeaderTableCell sx={{ width: getColumnWidh() }}>
                            {getSortedColumnHeader(t('device-list-clinic-header'), "clinic")}
                        </HeaderTableCell>}

                        <HeaderTableCell sx={{ minWidth: '100px' }}>
                            {getSortedColumnHeader(t('cloud-member-list-status-header'), "active")}
                        </HeaderTableCell>
                        {isSystemAdmin && <HeaderTableCell sx={{ width: '20px' }}></HeaderTableCell>}
                        <HeaderTableCell sx={{ minWidth: '20px', width: '20px' }}></HeaderTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {renderList()}
                </TableBody>
            </Table >
        </TableContainer >
    );
};
export default DeviceList;
