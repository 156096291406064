import { Divider, Popover, Stack, styled } from "@mui/material";
import { PopoverEntry } from "../../CloudMember/DetailsPanel/DetailsHeader";
import DocumentIcon from "../../../icons/DocumentIcon";
import LogoutIcon from "../../../icons/LogoutIcon";
import Avatar from "../../Avatar/Avatar";
import { roleName } from "../../ResultAssignment/fragments/SessionDetailsBox";
import TwoArrowsUpIcon from "../../../icons/TwoArrowsUpIcon";
import { tinyFont } from "../../../assets/theme";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";
import DashboardsIcon from "../../../icons/DashboardsIcon";
import { useAppSelector } from "../../../store";
import { selectIsClinicAdmin, selectIsManufacturerAdmin, selectIsRetailerAdmin, selectIsSystemaAdmin } from "../../../store/authSlice";
import SettingsIcon from "../../../icons/SettingsIcon";

const ArrowUp = styled(TwoArrowsUpIcon)(({ theme }) => ({
    fontSize: tinyFont,
    '& path': {
        fill: theme.palette.primary.main
    }
}));

type Props = {
    open: boolean;
    hideMenu: () => void;
    anchorEl: HTMLButtonElement | undefined;
    givenName: string;
    sureName: string;
    role: string;
    setShowVersionsDialog: (flag: boolean) => void;
    setShowSettingsDialog: (flag: boolean) => void;
    logOut: () => void;
};
const HeaderDropDown = ({ open, hideMenu, anchorEl, givenName, sureName, role, setShowSettingsDialog, setShowVersionsDialog, logOut }: Props) => {
    const isSystemAdmin = useAppSelector(selectIsSystemaAdmin);
    const isManufacturerAdmin = useAppSelector(selectIsManufacturerAdmin);
    const isRetailerAdmin = useAppSelector(selectIsRetailerAdmin);
    const isClinicAdmin = useAppSelector(selectIsClinicAdmin);
    const navigate = useNavigate();

    const gotToDeveloperPage = () => {
        navigate('/developer');

    };

    const getSettingsLabel = () => {
        if (isManufacturerAdmin) {
            return t('header-dropdown-manufacturer-settings');
        }
        if (isRetailerAdmin) {
            return t('header-dropdown-retailer-settings');
        }
        if (isClinicAdmin) {
            return t('header-dropdown-clinic-settings');
        }
    };

    return (
        <Popover
            open={open}
            onClose={hideMenu}
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 10,
                horizontal: 'center',
            }}
            sx={{ userSelect: 'none' }}>
            <Stack direction="column" spacing={1} sx={{ padding: '10px' }}>
                <Stack direction="row" spacing={2} pb="10px">
                    <Stack pt={'5px'}><Avatar name={`${givenName} ${sureName}`.trim()} /></Stack>
                    <Stack direction="column" >
                        <Stack pt="2px" sx={{ fontWeight: 'bold' }}>{givenName ?? '?'} {sureName ?? '?'}</Stack>
                        <Stack >{roleName(role)}</Stack>
                    </Stack>
                    <div style={{ cursor: 'pointer' }}>
                        <ArrowUp />
                    </div>
                </Stack>
                <Divider />
                {(isManufacturerAdmin || isRetailerAdmin || isClinicAdmin) && <PopoverEntry pb="10px" pt="10px" onClick={() => { setShowSettingsDialog(true); }}><SettingsIcon sx={{ marginRight: '10px' }} />{getSettingsLabel()}</PopoverEntry>}
                <PopoverEntry pb="10px" pt="10px" onClick={() => setShowVersionsDialog(true)}><DocumentIcon sx={{ marginRight: '10px' }} />{t('header-drop-down-versioninfo-label')}</PopoverEntry>
                <Divider />
                {isSystemAdmin && <PopoverEntry pb="10px" pt="10px" onClick={() => gotToDeveloperPage()}><DashboardsIcon sx={{ marginRight: '10px' }} />Entwickler Seite</PopoverEntry>}
                {isSystemAdmin && <Divider />}
                <PopoverEntry pb="10px" pt="10px" onClick={logOut}><LogoutIcon sx={{ marginRight: '10px' }} />{t('header-drop-down-logout-label')}</PopoverEntry>
            </Stack>
        </Popover >
    );
};

export default HeaderDropDown;
