import { styled } from '@mui/material';
import { ReactNode } from 'react';
import theme from '../../assets/theme';

const ContentGrid = styled('div')({
    display: 'flex',
    height: 'calc(100% - 150px)',
    padding: '20px',
    backgroundColor: theme.palette.inactive.main
});


const LeftGrid = styled('div', {
    shouldForwardProp: (prop) => !['width'].includes(prop as string),
})<{ width: string; }>(({ width }) => ({
    backgroundColor: '#FFF',
    boxShadow: '1px 3px 6px #00000029',
    borderRadius: '10px',
    height: '100%',
    width: width
}));


const RightTopGrid = styled('div', {
    shouldForwardProp: (prop) => !['width'].includes(prop as string),
})<{ width: string; height: number; }>(({ width, height }) => ({
    backgroundColor: '#FFF',
    boxShadow: '1px 3px 6px #00000029',
    borderRadius: '10px',
    height: height,
    marginLeft: '20px',
    width: width,
}));

const RightBottomGrid = styled('div', {
    shouldForwardProp: (prop) => !['width'].includes(prop as string),
})<{ width: string; height: number; }>(({ width, height }) => ({
    backgroundColor: '#FFF',
    boxShadow: '1px 3px 6px #00000029',
    borderRadius: '10px',
    marginTop: '20px',
    height: `calc(100% - ${height}px)`,
    marginLeft: '20px',
    width: width,
}));

type TwoColumnsProps = {
    menuHeight?: number;
    leftWidth?: string;
    rightWidth?: string;
    leftComponent: ReactNode;
    rightTopComponent: ReactNode;
    rightBottomComponent: ReactNode;
};

const ThreeComponents = ({ menuHeight = 160, leftWidth, rightWidth, leftComponent, rightTopComponent, rightBottomComponent }: TwoColumnsProps) => {
    return (
        <ContentGrid>
            <LeftGrid width={leftWidth ?? '65%'}>
                {leftComponent}
            </LeftGrid>
            <div style={{ marginRight: '20px', width: rightWidth ?? '35%' }}>
                <RightTopGrid width="100%" height={menuHeight}>
                    {rightTopComponent}
                </RightTopGrid>
                <RightBottomGrid width="100%" height={menuHeight + 20}>
                    {rightBottomComponent}
                </RightBottomGrid>
            </div>
        </ContentGrid >
    );
};

export default ThreeComponents;
