import { useAppSelector } from "../../store";
import { selectIsClinicAdmin, selectIsClinicUser, selectIsManufacturerAdmin, selectIsSystemaAdmin, selectIsRetailerAdmin } from "../../store/authSlice";
import NavigationButton from "./NavigationButton";
import PatientsIcon from "../../icons/PatientsIcon";
import ResultAssignmentIcon from "../../icons/ResultAssignmentIcon";
import DeviceIcon from "../../icons/DeviceIcon";
import ClinicIcon from "../../icons/ClinicIcon";
import RetailerIcon from "../../icons/RetailerIcon";
import ManufacturerIcon from "../../icons/ManufacturerIcon";
import UserIcon from "../../icons/UserIcon";
import ResultAssignmentNavigationButton from "./ResultAssignmentNavigationButton";
import { t } from "i18next";

export const MainNavigation = () => {
    const isSystemAdmin = useAppSelector(selectIsSystemaAdmin);
    const isManufacturerAdmin = useAppSelector(selectIsManufacturerAdmin);
    const isRetailerAdmin = useAppSelector(selectIsRetailerAdmin);
    const isClinicAdmin = useAppSelector(selectIsClinicAdmin);
    const isClinicUser = useAppSelector(selectIsClinicUser);
    return (
        <nav style={{ height: '70px', lineHeight: '70px' }}>
            {isClinicUser &&
                <NavigationButton to="/patients" label={t('navigation-button-patients')} Icon={PatientsIcon} />}

            {!isClinicUser && <NavigationButton to="/user" label={t('navigation-button-user')} Icon={UserIcon} />}

            {(isSystemAdmin) &&
                <NavigationButton to="/locked-accounts" label={t('navigation-button-locked-accounts')} Icon={UserIcon} />
            }

            {(isSystemAdmin || isRetailerAdmin) &&
                <NavigationButton to="/manufacturer" label={t('navigation-button-manufacturer')} Icon={ManufacturerIcon} />
            }

            {(isSystemAdmin || isManufacturerAdmin || isClinicAdmin) &&
                <NavigationButton to="/retailer" label={t('navigation-button-retailer')} Icon={RetailerIcon} />}

            {(isSystemAdmin || isRetailerAdmin) &&
                <NavigationButton to="/clinic" label={t('navigation-button-clinic')} Icon={ClinicIcon} />}

            {!isClinicUser && <NavigationButton to="/devices" label={t('navigation-button-device')} Icon={DeviceIcon} />}

            {isManufacturerAdmin && <NavigationButton to="/device-logs" label={t('navigation-button-device-logs')} Icon={ResultAssignmentIcon} />}

            {isClinicUser &&
                <ResultAssignmentNavigationButton to="/result-assignment" label={t('navigation-button-result-assignment')} Icon={ResultAssignmentIcon} />}
        </nav>


    );
};
