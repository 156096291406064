import { TextField, styled } from "@mui/material";
import { largeFont } from "../../assets/theme";

const StyledSearchField = styled(TextField)(({ theme }) => ({
    width: '100%',
    paddingRight: '0px',
    paddingTop: '0px',
    paddingBottom: '0px',
    '& .MuiOutlinedInput-root': {
        borderRadius: '5px',
        color: theme.palette.secondary.contrastText,
        fontSize: largeFont,
        height: '48px',
        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: theme.palette.primary.light,
            borderWidth: '1px'
        }, '&.Mui-focused fieldset': {
            borderColor: theme.palette.primary.light,
            borderWidth: '1px'
        },
        '&:hover fieldset': {
            borderColor: theme.palette.primary.light,
            borderWidth: '1px'
        },
    }
}));

export default StyledSearchField;
