import { styled, Button, ButtonProps } from '@mui/material';

const StyledNaviagionButton = styled(Button)({
    fontSize: '22px',
    borderRadius: 0,
    paddingRight: '30px',
    paddingLeft: '20px',
    '& .MuiButton-startIcon': {
        '& > *:nth-of-type(1)': {
            fontSize: '22px',
        },
    },
});

const NavigationBarButton = ({ ...props }: ButtonProps) => {
    props.variant = "text";
    props.color = "primary";
    return <StyledNaviagionButton {...props} sx={{ whiteSpace: 'nowrap' }} disableRipple={true} />;
};

export default NavigationBarButton;
