import { MappedTherapySessionData } from '../../../types/types';
import { Box, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import InfoIcon from '../../../icons/InfoIcon';
import theme, { mediumFont, smallFont } from '../../../assets/theme';
import LyraIcon from '../../../icons/LyraIcon';
import { useState } from 'react';
import ShowCommentDialog from './ShowCommentDialog';
import { getDeviceKey, getLabelField, getTleExerciseDurationClock, getTleTwoResultsString, getUnitField } from '../../ResultAssignment/fragments/SessionDetailsBox';
import { t } from 'i18next';


export enum BoxType {
    ASSIGNED,
    UNASSIGNED
}

export interface BoxProps {
    session: MappedTherapySessionData;
}

const DetailsBox = ({ session }: BoxProps) => {
    const [showComment, setShowComment] = useState(false);
    const perceptionValues: string[] = t('perception-of-load', { returnObjects: true });

    const getCommentIcon = () => {
        if (session.comment) {
            return <InfoIcon color="success" onClick={() => setShowComment(true)} style={{ fontSize: '16pt' }} />;
        }
    };

    return (
        <>
            <Table sx={{ height: '100%' }}>
                <TableBody>
                    <TableRow>
                        <TableCell sx={{
                            borderBottom: 'none',
                            width: '20px',
                            padding: 0,
                            margin: 0,
                            verticalAlign: 'top',
                            textAlign: 'center'
                        }}>
                            <div style={{
                                height: 'calc(100% - 5px)',
                                verticalAlign: 'top',
                                textAlign: 'center',
                                width: '20px',
                                backgroundColor: 'white',
                                overflow: 'hidden',
                            }}>
                                <div style={{
                                    marginTop: '3px',
                                    borderRadius: '10px',
                                    backgroundColor: theme.palette.linkgray.main,
                                    height: '20px',
                                    width: '20px',
                                }}></div>
                                <div style={{
                                    height: '100%',
                                    display: 'inline-block',
                                    width: '3px',
                                    backgroundColor: theme.palette.linkgray.main,
                                    marginTop: '10px'
                                }}></div>
                            </div>
                        </TableCell>

                        <TableCell sx={{ fontSize: mediumFont, height: '100%', borderBottom: 'none', padding: 0, margin: 0 }}>
                            <div style={{
                                paddingBottom: '20px',
                                verticalAlign: 'top',
                                paddingLeft: '10px',
                            }}>
                                <Box sx={{
                                    fontWeight: 'bold',
                                    display: 'inline-flex',
                                    justifyContent: 'space-between'
                                }}>
                                    <div style={{ paddingRight: '10px' }}>{getDeviceKey(session)}</div>
                                    {getCommentIcon()}
                                </Box>

                                <div style={{
                                    fontSize: smallFont, paddingTop: '2px', paddingBottom: '5px'
                                }}>{getTleExerciseDurationClock(session)}</div>
                                <div>{session[getLabelField('tlResult2')]}: {session.tlResult2} {session[getUnitField('tlResult2')]}</div>
                                <div>{session[getLabelField('tlResult3')]}: {getTleTwoResultsString(session, 'tlResult3', 'tlResult4')}</div>
                                <div>{session[getLabelField('tlResult5')]}: {session.tlResult5} {session[getUnitField('tlResult5')]}</div>
                                <div>{session[getLabelField('tlResult6')]}: {perceptionValues[parseInt(session.tlResult6 ?? '4')]}</div>
                            </div>
                        </TableCell>

                        <TableCell sx={{
                            height: '100%',
                            margin: 0,
                            padding: 0,
                            borderBottom: 'none',
                            paddingRight: '0px',
                            paddingBottom: '20px',
                            verticalAlign: 'top',
                        }}>
                            <Table sx={{
                                height: '100%',
                                borderBottom: 'none'
                            }}>
                                <TableBody>
                                    <TableRow>
                                        <TableCell sx={{
                                            height: '100%',
                                            borderBottom: 'none',
                                            padding: 0,
                                            margin: 0,
                                            textAlign: 'right',
                                            verticalAlign: 'top',
                                        }}>
                                            <LyraIcon sx={{ fontSize: '50px' }} />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{ marginBottom: '0px', paddingBottom: '0px', borderBottom: 'none', paddingRight: '7px', height: '100%', textAlign: 'right', verticalAlign: 'bottom' }}>
                                            <Typography fontWeight="bold">{session.tlResult1}</Typography>
                                            {session[getUnitField('tlResult1')]}
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>

                        </TableCell>

                    </TableRow>
                </TableBody>
            </Table >
            {showComment && <ShowCommentDialog session={session} setShowComment={setShowComment} />
            }
        </>
    );
};

export default DetailsBox;
