import { styled } from "@mui/material";
import { avatarColors } from "../../assets/theme";

const AvatarDiv = styled('div', {
    shouldForwardProp: (prop) => !['size'].includes(prop as string),
})<{ size: string; }>(({ theme, size }) => ({
    border: 'solid 2px #F9B000',
    backgroundColor: '#F9E9CE',
    color: '#F9B000',
    width: size === 'small' ? '40px' : '83px',
    height: size === 'small' ? '40px' : '83px',
    minWidth: size === 'small' ? '40px' : '83px',
    minHeight: size === 'small' ? '40px' : '83px',
    borderRadius: size === 'small' ? '40px' : '83px',
    textAlign: 'center',
    lineHeight: size === 'small' ? '38px' : '81px',
    fontSize: size === 'small' ? '18px' : '24px',
    fontWeight: 'normal',
}));

type AvatarProps = {
    name?: string;
    size?: string;
};

const getColor = (char1: number, char2: number) => {
    const hash = (char1 + char2) / 2;
    return Math.round(hash % 3);
};

const Avatar = ({ size, name }: AvatarProps) => {
    const fields = name && name.trim().length > 0 ? name.split(' ') : ['?', '?'];
    const char1 = fields[0].charAt(0);
    const char2 = fields.length > 1 ? fields[1].charAt(0) : fields[0].charAt(0);
    const monogram = `${char1}${fields.length > 1 ? char2 : ''}`.trim();
    const idx = getColor(char1.charCodeAt(0), char2.charCodeAt(0));
    const color = avatarColors[idx];
    return (<AvatarDiv sx={{ color: color.color, borderColor: color.color, backgroundColor: color.background }} size={size ?? 'small'}>{monogram}</AvatarDiv>);
};

export default Avatar;
