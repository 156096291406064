import { TableRow, styled } from "@mui/material";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "& > .MuiTableCell-root": {
        "&:last-child, &:first-of-type": {
            border: 0,
        },
    },
    "&.Mui-selected, &.Mui-selected:hover": {
        backgroundColor: theme.palette.lightgreen.main,
        "& > .MuiTableCell-root": {
            "&:last-child, &:first-of-type": {
                border: 0,
            },
            color: theme.palette.primary.main,
            borderTop: `solid 2px ${theme.palette.darkgreen.main}`,
            borderBottom: `solid 2px ${theme.palette.darkgreen.main}`,
        },
    }

}));

export default StyledTableRow;
