import { Dialog, Divider, Grid, TextField, styled } from "@mui/material";
import TheraButton from "../../TheraButtons/TheraButton";
import { t } from "i18next";
import { useRef } from "react";
import StyledTextField from "../../StyledTextField/StyledTextField";
import { isEmpty } from "lodash";
import { enqueueSnackbar } from "notistack";
import { ErrorMessage } from "../../CloudMember/DetailsPanel/AddCloudMember";
import { useAddPatientMutation } from "../../../api/patientApi";
import { mediumFont } from "../../../assets/theme";

const MultilineField = styled(TextField)(({ theme }) => ({
    padding: 0,
    borderRadius: '5px',
    borderColor: theme.palette.primary.light,
    width: '100%',
    '& .MuiOutlinedInput-root': {
        color: theme.palette.secondary.contrastText,
        fontSize: mediumFont,
        lineHeight: '20px',
        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: theme.palette.primary.light,
            borderWidth: '1px'
        }
    }
}));


type Props = {
    onClose: () => void;
};
const NewPatientDialog = ({ onClose }: Props) => {
    const [addPatient] = useAddPatientMutation();
    const patientCode = useRef<HTMLInputElement>();
    const sureName = useRef<HTMLInputElement>();
    const givenName = useRef<HTMLInputElement>();
    const diagnose = useRef<HTMLInputElement>();
    const therapyGoals = useRef<HTMLInputElement>();
    const therapyHints = useRef<HTMLInputElement>();

    const save = () => {
        const patientcode = patientCode.current?.value;
        if (isEmpty(patientcode)) {
            enqueueSnackbar(ErrorMessage(t('no-patient-code-error')), { anchorOrigin: { horizontal: 'left', vertical: 'top' }, variant: 'error' });
            return;
        }
        const request = {
            patientCode: patientcode ?? '',
            sureName: sureName.current?.value,
            givenName: givenName.current?.value,
            diagnose: diagnose.current?.value,
            therapyGoals: therapyGoals.current?.value,
            therapyHints: therapyHints.current?.value
        };

        addPatient(request).then((result) => {
            if ('error' in result) {
                return;
            }
            onClose();
        });
    };

    return <Dialog open={true}>
        <Grid container xs={12} sx={{ padding: '20px' }}>
            <Grid container item direction="column" spacing={1}>
                <Grid item fontWeight="bold" pb="20px" fontSize="20px">{t('add-patient-dialog-title')}</Grid>
                <Divider />
                <Grid item fontWeight="bold">{t('cloud-member-list-patient-code-header')}*</Grid>
                <Grid item ><StyledTextField inputRef={patientCode} fullWidth size="small" /></Grid>

                <Grid item><Divider sx={{ height: '10px' }} /></Grid>

                <Grid item container>
                    <Grid item fontWeight="bold" xs={6}>Name</Grid>
                    <Grid item fontWeight="bold" xs={6}><div style={{ paddingLeft: '10px' }} >Vorname</div></Grid>
                    <Grid item xs={6}><StyledTextField inputRef={sureName} sx={{ paddingRight: '10px', width: '100%' }} size="small" /></Grid>
                    <Grid item xs={6}><StyledTextField inputRef={givenName} sx={{ paddingLeft: '10px', width: '100%' }} size="small" /></Grid>
                </Grid>

                <Grid item><Divider sx={{ height: '10px' }} /></Grid>

                <Grid item fontWeight="bold">Diagose</Grid>
                <Grid item><MultilineField inputRef={diagnose} multiline fullWidth rows={2} size="small" /></Grid>

                <Grid item><Divider sx={{ height: '10px' }} /></Grid>

                <Grid item fontWeight="bold">Therapieziele</Grid>
                <Grid item><MultilineField InputLabelProps={{ shrink: true }} inputRef={therapyGoals} multiline fullWidth rows={2} size="small" /></Grid>

                <Grid item><Divider sx={{ height: '10px' }} /></Grid>

                <Grid item fontWeight="bold">Therapiehinweise</Grid>
                <Grid item><MultilineField inputRef={therapyHints} multiline fullWidth rows={2} size="small" /></Grid>
            </Grid>

            <Grid pt="20px" item container direction="row" alignItems="center" justifyContent="space-between">
                <TheraButton sx={{ width: '48%' }} onClick={onClose} size="medium" color="lightgreen" variant="contained">{t('cloud-member-form-cancel-button')}</TheraButton>
                <TheraButton sx={{ width: '48%' }} onClick={() => save()} type="submit" size="medium" color="success" variant="contained">{t('cloud-member-form-save-button')}</TheraButton>
            </Grid>

        </Grid>

    </Dialog>;
};

export default NewPatientDialog;
