import { styled } from "@mui/material";
import LyraIcon from "../../icons/LyraIcon";

const AvatarDiv = styled('div', {
    shouldForwardProp: (prop) => !['size'].includes(prop as string),
})<{ size: string; }>(({ size }) => ({
    border: 'solid 2px',
    fontSize: size === 'small' ? '22px' : '48px',
    lineHeight: size === 'small' ? '42px' : '90px',
    width: size === 'small' ? '40px' : '83px',
    height: size === 'small' ? '40px' : '83px',
    minWidth: size === 'small' ? '40px' : '83px',
    minHeight: size === 'small' ? '40px' : '83px',
    borderRadius: size === 'small' ? '40px' : '83px',
    textAlign: 'center',
}));

type AvatarProps = {
    typ?: string;
    size?: string;
};

const DeviceAvatar = ({ size, typ }: AvatarProps) => {
    const monogram = typ ? typ.substring(0, 2) : '*';
    const Icon = () => {
        if (typ === 'lyra') {
            return <LyraIcon sx={{
                fontSize: size === 'small' ? '40px' : '48px',
            }} />;
        }
        return <>{monogram}</>;
    };

    const getIcon = () => {
        if (size === 'small') {
            return <Icon />;
        }
        return <AvatarDiv sx={{ color: 'gray', borderColor: 'gray', backgroundColor: '#f0f0f0' }} size={size ?? 'small'}><Icon /></AvatarDiv>;
    };
    return getIcon();
};

export default DeviceAvatar;
