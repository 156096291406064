import { useDownloadDeviceLogMutation, useGetDeviceLogsQuery } from "../../../api/deviceApi";
import { Box, Divider, Grid, Stack } from "@mui/material";
import DeviceAvatar from "../../Avatar/DeviceAvatar";
import DetailsHeader from "../../CloudMember/DetailsPanel/DetailsHeader";
import StyledLabel from "../../StyledTextField/StyledLabel";
import StyledText from "../../StyledTextField/StyledText";
import FooterText from "../../StyledTextField/FooterText";
import { dateString, tleTimeString } from "../../ResultAssignment/fragments/SessionDetailsBox";
import { t } from "i18next";
import DownloadIcon from "../../../icons/DownloadIcon";
import { largeFont } from "../../../assets/theme";
import { DeviceLog, DeviceMessage } from "../../../types/types";

type DetailsProps = {
    deviceId: string;
    device: DeviceMessage;
};

const LogFiles = ({ device, deviceId }: DetailsProps) => {
    const { data, isLoading } = useGetDeviceLogsQuery(deviceId);//, { refetchOnMountOrArgChange: true });
    const [downloadLogFile] = useDownloadDeviceLogMutation();

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (!data) {
        return <Box style={{
            padding: '20px', height: '100%', fontSize: largeFont, display: 'flex', alignItems: 'center', justifyContent: 'center'
        }}>{t('no-log-found-message')}</Box>;
    }

    const downloadFile = (log: DeviceLog) => {
        downloadLogFile(log.id);
    };

    return (
        <Box sx={{ height: '100%', paddingLeft: '20px', paddingRight: '10px', paddingBottom: '10px' }} >
            <Stack direction="column" sx={{ height: '100%', paddingRight: '10px', overflow: 'auto' }} >
                <Stack sx={{ display: 'flex', paddingTop: '20px', paddingBottom: '20px', width: '100%', }} direction="row">
                    <div style={{ marginRight: '20px' }}>
                        <DeviceAvatar size="large" typ={device.type} />
                    </div>
                    <DetailsHeader
                        memberType={device.therapyMethod[0] ?? ''}
                        isActive={Boolean(device.active)}
                        isDeleted={Boolean(device.deleted)}
                        name={device.name}
                    />
                </Stack >

                <Divider />

                <Stack sx={{ display: 'flex', paddingTop: '20px', paddingBottom: '20px', width: '100%', }} direction="column">
                    <StyledLabel>{t('cloud-member-form-comment-label')}</StyledLabel>
                    <StyledText>{device.comment}</StyledText>
                </Stack>

                <Divider />

                <Stack sx={{ display: 'flex', paddingTop: '20px', width: '100%', }} direction="column">
                    <StyledLabel sx={{ paddingBottom: '5px' }}>{t('cloud-member-logfiles-label')}</StyledLabel>
                </Stack>
                <Stack sx={{ display: 'flex', overflow: 'auto', height: '100%', width: '100%' }} direction="column">
                    {
                        data.map((log, index) => {
                            return (<Stack sx={{ height: '28px', display: 'flex', justifyContent: 'space-around', width: '100%' }} key={log.id} direction="row" spacing={2}>
                                <div style={{}}><StyledText>{tleTimeString(log.created)}</StyledText></div>
                                <div style={{}}><StyledText >{log.size} bytes</StyledText></div>
                                <Box onClick={() => downloadFile(log)} sx={{ paddingRight: '10px', cursor: 'pointer' }}>
                                    <DownloadIcon />
                                </Box>
                            </Stack>
                            );
                        })
                    }
                </Stack>

                <Stack style={{ justifyContent: 'end', paddingTop: '20px' }} >
                    <Divider sx={{ marginBottom: '15px' }} />
                    <Grid container>
                        <Grid item xs={6}>
                            <FooterText fontWeight="bold">{t('cloud-member-form-updated-label')}</FooterText>
                        </Grid>
                        <Grid item xs={6} sx={{ textAlign: 'right' }}>
                            <FooterText fontWeight="bold">{t('cloud-member-form-created-label')}</FooterText>
                        </Grid>
                        <Grid item xs={6}>
                            <FooterText>{dateString(device.lastUpdated ?? device.created)}</FooterText>
                        </Grid>
                        <Grid item xs={6} sx={{ textAlign: 'right' }}>
                            <FooterText>{dateString(device.created)}</FooterText>
                        </Grid>
                    </Grid>
                </Stack>
            </Stack>
        </Box >
    );
};

export default LogFiles;
