// Need to use the React-specific entry point to allow generating React hooks
import { MappedTherapySessionData, UpdateTherapySessionRequest } from '../types/types';
import { emptyBaseApi } from './emptyBaseApi';

export const therapySessionsApi = emptyBaseApi.injectEndpoints({
    endpoints: (builder) => ({
        getAssignedTherapieSessions: builder.query<MappedTherapySessionData[], string>({
            query: (patientId) => ({
                url: `sessions/get_assigned_session_data?patientId=${patientId}`,
                method: 'GET',
            }),
            providesTags: ['TherapySession'],
        }),
        updateTherapySessionData: builder.mutation<MappedTherapySessionData, UpdateTherapySessionRequest>({
            query: (param) => ({
                url: 'sessions/update_session',
                method: 'POST',
                body: param
            }),
            invalidatesTags: ['TherapySession'],
        }),
        getUnassignedTherapieSessions: builder.query<MappedTherapySessionData[], void>({
            query: () => ({
                url: 'sessions/get_unassigned_session_data',
                method: 'GET',
            }),
            providesTags: ['TherapySession'],
        }),
        getTherapySession: builder.query<MappedTherapySessionData, string>({
            query: (id) => ({
                url: `sessions/get?sessionId=${id}`,
                method: 'GET',
            }),
            providesTags: ['TherapySession'],
        }),
        deleteTherapySession: builder.mutation<MappedTherapySessionData[], string>({
            query: (id) => ({
                url: `sessions/delete?sessionId=${id}`,
                method: 'GET',
            }),
            invalidatesTags: ['TherapySession'],
        }),
        undeleteTherapySession: builder.mutation<MappedTherapySessionData[], string>({
            query: (id) => ({
                url: `sessions/undelete?sessionId=${id}`,
                method: 'GET',
            }),
            invalidatesTags: ['TherapySession'],
        }),

    }),
    overrideExisting: true,
});

export const { useGetTherapySessionQuery, useUndeleteTherapySessionMutation, useDeleteTherapySessionMutation, useUpdateTherapySessionDataMutation, useGetAssignedTherapieSessionsQuery, useGetUnassignedTherapieSessionsQuery } = therapySessionsApi;
