import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../store';
import { Box, InputAdornment, Stack, Typography, styled } from '@mui/material';
import { signIn } from '../../api/authApi';
import { closeSnackbar, enqueueSnackbar } from 'notistack';
import VisibilityOffIcon from '../../icons/VisibilityOffIcon';
import VisibilityOnIcon from '../../icons/VisibilityOnIcon';
import StyledTextField from '../../components/StyledTextField/StyledTextField';
import TheraButton from '../../components/TheraButtons/TheraButton';
import { isEmpty } from '../ResultAssignment/fragments/SessionDetailsBox';
import { useNavigate } from 'react-router-dom';
import { UserRole } from '../../store/authSlice';

const LoginTextField = styled(StyledTextField)(({ theme }) => ({
    paddingTop: '3px',
    '& .MuiOutlinedInput-root': {
        height: '50px',
        width: '504px',
    }
}));

const StyledBox = styled(Box)(({ theme }) => ({
    position: 'absolute',
    display: 'flex',
    alignContent: 'center',
    width: '100%',
    height: '100%',
    backgroundImage: 'url("images/loginBackground.png")',
    backgroundRepeat: 'no-repeat',
    backgroundOrigin: 'padding-box',
    backgroundSize: 'cover',
    opacity: 1,
}));

const LoginErrorMessage = (message: string) => {
    return (<div style={{ padding: '5px' }}>{message}</div>);
};

const LoginPage = () => {
    const { t } = useTranslation();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const dispatch = useAppDispatch();
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async (event: { preventDefault: () => void; }) => {
        event.preventDefault();
        if (isEmpty(password)) {
            return;
        }
        closeSnackbar('inactivity-logout-error');
        dispatch(signIn({ email: email, password: password })).then((result) => {
            if (result.payload.statusCode) {
                if (result.payload.statusCode === 500) {
                    enqueueSnackbar(LoginErrorMessage(result.payload.message), { key: 'logon-retry-error', preventDuplicate: true, variant: 'error' });
                    return;
                }
                enqueueSnackbar(LoginErrorMessage(result.payload.message), { key: 'logon-error', preventDuplicate: true, variant: 'error' });
                return;
            }
            switch (result.payload.role) {
                case UserRole.ClinicAdmin:
                    navigate('/user');
                    break;
                case UserRole.ClinicUser:
                    navigate('/patients');
                    break;
                case UserRole.ManufacturerAdmin:
                    navigate('/devices');
                    break;
                case UserRole.RetailerAdmin:
                    navigate('/clinic');
                    break;
                default:
                    navigate('/user');
            }
        });
    };

    useEffect(() => {
        closeSnackbar('logon-retry-error');
        closeSnackbar('logon-error');
    }, []);

    return (
        <StyledBox>
            <div style={{
                position: 'absolute',
                right: 'calc(50% - 300px)',
                top: 'calc(50% - 367px)',
                paddingLeft: '45px',
                paddingTop: '52px',
                height: '734px',
                width: '603px',
                backgroundColor: '#FFFFFF',
                boxShadow: '1px 3px 6px #00000029',
                borderRadius: '10px',
                opacity: 1
            }}>

                <form onSubmit={handleSubmit}>
                    <img src="images/THERA-Trainer_by_medica_Logo_2022_small.png" alt="THERA-Trainer" style={{ position: 'relative', right: '-249px' }} />
                    <Stack spacing={0}>
                        <Typography color="primary" sx={{ marginTop: '99px', }} fontSize={22} fontWeight="bold">{t('login-email-input-label')}</Typography>
                        <LoginTextField
                            placeholder={t('login-email-placeholder')}
                            color="primary"
                            variant="outlined"
                            autoFocus
                            type="text"
                            value={email}
                            onChange={(e) => setEmail(e.target.value.trim())}
                        />
                        <Typography sx={{ marginTop: '30px', }} color="primary" fontSize={22} fontWeight="bold">{t('login-password-input-label')}</Typography>
                        <LoginTextField
                            placeholder={t('login-password-placeholder')}
                            variant="outlined"
                            color="primary"
                            type={showPassword ? "text" : "password"}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            InputProps={
                                {
                                    endAdornment: (
                                        <InputAdornment position="end"> {
                                            showPassword ?
                                                (<VisibilityOffIcon style={{ cursor: 'pointer' }} onClick={() => setShowPassword(!showPassword)} />)
                                                :
                                                (<VisibilityOnIcon style={{ cursor: 'pointer' }} onClick={() => setShowPassword(!showPassword)} />)
                                        }
                                        </InputAdornment>
                                    ),
                                }
                            }
                        />
                        <TheraButton type="submit" sx={{
                            borderRadius: '5px',
                            marginTop: '187px',
                            fontSize: '24px',
                            fontWeight: 'bold', height: '60px', width: '504px'
                        }}
                            color="darkgreen" variant="contained">{t('login-button-label')}</TheraButton>
                    </Stack>
                </form >
            </div>
        </StyledBox >
    );

};

export default LoginPage;
