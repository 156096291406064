import { Divider, Grid, Stack } from "@mui/material";
import { SetStateAction, useRef } from "react";
import Avatar from "../../Avatar/Avatar";
import { Pages } from "../MemberListPanels/listPanelGlobals";
import { CloudMember } from "../../../types/types";
import { t } from "i18next";
import { enqueueSnackbar } from "notistack";
import { ErrorMessage } from "./AddCloudMember";
import StyledLabel from "../../StyledTextField/StyledLabel";
import FooterText from "../../StyledTextField/FooterText";
import StyledMultilineField from "../../StyledTextField/StyledMultilineField";
import StyledTextField from "../../StyledTextField/StyledTextField";
import TheraButton from "../../TheraButtons/TheraButton";
import { dateString, isEmpty } from "../../ResultAssignment/fragments/SessionDetailsBox";

type DetailsProps = {
    setPage?: (value: SetStateAction<Pages>) => void;
    data?: CloudMember;
    update: (params: any) => void;
};

const EditCloudMember = ({ update, data, setPage }: DetailsProps) => {
    const name = useRef<HTMLInputElement>();
    const comment = useRef<HTMLInputElement>();
    const zip = useRef<HTMLInputElement>();
    const city = useRef<HTMLInputElement>();
    const street = useRef<HTMLInputElement>();
    const streetNr = useRef<HTMLInputElement>();
    const phone = useRef<HTMLInputElement>();
    const contact = useRef<HTMLInputElement>();
    console.log('### data %s', JSON.stringify(data, null, "\t"));

    const handleSubmit = async (event: { preventDefault: () => void; }) => {
        event.preventDefault();
        if (data) {
            if (name.current && isEmpty(name.current.value)) {
                enqueueSnackbar(ErrorMessage(t('cloud-member-form-no-name-error')), { anchorOrigin: { horizontal: 'left', vertical: 'top' }, variant: 'error' });
                name.current.focus();
                return;
            }
            const params = {
                name: name.current?.value ?? '',
                comment: comment.current?.value ?? '',
                email: data?.email ?? '',
                zip: zip.current?.value ?? '',
                city: city.current?.value ?? '',
                street: street.current?.value ?? '',
                streetNr: streetNr.current?.value ?? '',
                phone: phone.current?.value ?? '',
                contact: contact.current?.value ?? '',
            };
            update(params);
        }
    };

    return (
        <form onSubmit={handleSubmit} style={{ padding: '20px', height: '100%' }}>
            <Stack direction="column" sx={{ paddingRight: '15px', height: '100%', overflow: 'auto' }} >
                <Stack direction="row" spacing={0}>
                    <Avatar size="large" name={data?.name} />
                    <Stack direction="column" spacing={0} sx={{
                        width: 'calc(100% - 83px)',
                    }}>
                        <StyledLabel pl={'20px'}>{t('cloud-member-form-name-label')}</StyledLabel>
                        <StyledTextField
                            sx={{ paddingLeft: '20px' }}
                            variant="outlined"
                            color="primary"
                            autoComplete="off"
                            type="text"
                            defaultValue={data?.name}
                            inputProps={{ "data-testid": "name-field" }}
                            inputRef={name}
                        />
                    </Stack>
                </Stack>
                <Divider sx={{ marginTop: '20px' }} />
                <Stack pt="10px" direction="column" spacing={0}>
                    <StyledLabel>{t('cloud-member-form-comment-label')}</StyledLabel>
                    <StyledMultilineField
                        variant="outlined"
                        color="primary"
                        multiline
                        rows={3}
                        defaultValue={data?.comment}
                        inputRef={comment}
                    />
                </Stack>

                <Divider sx={{ marginTop: '20px' }} />


                <Grid container pt={'10px'}>
                    <Grid item xs={4}>
                        <StyledLabel>{t('cloud-member-form-zip-label')}</StyledLabel>
                    </Grid>
                    <Grid item xs={8} >
                        <StyledLabel>{t('cloud-member-form-city-label')}</StyledLabel>
                    </Grid>
                    <Grid item xs={4}>
                        <StyledTextField
                            sx={{ paddingRight: '20px' }}
                            variant="outlined"
                            color="primary"
                            autoComplete="off"
                            type="text"
                            defaultValue={data?.zip
                            }
                            inputRef={zip}
                        />
                    </Grid>
                    <Grid item xs={8}>
                        <StyledTextField
                            variant="outlined"
                            color="primary"
                            autoComplete="off"
                            type="text"
                            defaultValue={data?.city}
                            inputRef={city}
                        />
                    </Grid>
                </Grid>
                <Grid container pt={'10px'}>
                    <Grid item xs={9}>
                        <StyledLabel>{t('cloud-member-form-street-label')}</StyledLabel>
                    </Grid>
                    <Grid item xs={3}>
                        <StyledLabel>{t('cloud-member-form-street-nr-label')}.</StyledLabel>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={9}>
                        <StyledTextField
                            sx={{ paddingRight: '20px' }}
                            variant="outlined"
                            color="primary"
                            autoComplete="off"
                            type="text"
                            defaultValue={data?.street}
                            inputRef={street}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <StyledTextField
                            variant="outlined"
                            color="primary"
                            autoComplete="off"
                            type="text"
                            defaultValue={data?.streetNr}
                            inputRef={streetNr}
                        />
                    </Grid>
                </Grid>

                <Stack pt="10px" direction="column" spacing={0}>
                    <StyledLabel>{t('cloud-member-form-contact-label')}</StyledLabel>
                    <StyledTextField
                        variant="outlined"
                        color="primary"
                        autoComplete="off"
                        type="text"
                        defaultValue={data?.contact}
                        inputRef={contact}
                    />
                </Stack>
                <Stack pt="10px" direction="column" spacing={0}>
                    <StyledLabel>{t('cloud-member-form-phone-label')}</StyledLabel>
                    <StyledTextField
                        variant="outlined"
                        color="primary"
                        autoComplete="off"
                        type="text"
                        defaultValue={data?.phone}
                        inputRef={phone}
                    />
                </Stack>
                <Stack pt="10px" direction="column" spacing={0}>
                    <StyledLabel>{t('cloud-member-form-email-label')}</StyledLabel>
                    <StyledTextField
                        variant="outlined"
                        color="primary"
                        autoComplete="off"
                        type="text"
                        disabled
                        value={data?.email}
                    />
                </Stack>

                <Stack style={{ justifyContent: 'end', height: '100%', paddingTop: '25px' }} >
                    <Stack spacing={'20px'} direction="row">
                        <TheraButton sx={{ width: '50%' }} onClick={() => setPage ? setPage(Pages.DETAILS) : () => { }} size="medium" color="lightgreen" variant="contained">{t('cloud-member-form-cancel-button')}</TheraButton>
                        <TheraButton sx={{ width: '50%' }} type="submit" size="medium" color="success" variant="contained">{t('cloud-member-form-save-button')}</TheraButton>
                    </Stack>
                    <Divider sx={{ marginTop: '15px', marginBottom: '15px' }} />
                    <Grid container>
                        <Grid item xs={6}>
                            <FooterText fontWeight="bold">{t('cloud-member-form-updated-label')}</FooterText>
                        </Grid>
                        <Grid item xs={6} sx={{ textAlign: 'right' }}>
                            <FooterText fontWeight="bold">{t('cloud-member-form-created-label')}</FooterText>
                        </Grid>
                        <Grid item xs={6}>
                            <FooterText>{dateString(data?.lastUpdated ?? data?.created)}</FooterText>
                        </Grid>
                        <Grid item xs={6} sx={{ textAlign: 'right' }}>
                            <FooterText>{dateString(data?.created)}</FooterText>
                        </Grid>
                    </Grid>
                </Stack>
            </Stack >
        </form >
    );
};

export default EditCloudMember;
