import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
    AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import LyraIcon from '../../../../icons/LyraIcon';
import { Box, Typography } from '@mui/material';
import theme, { largeFont, smallFont } from '../../../../assets/theme';
import { DeviceMessage, Patient } from '../../../../types/types';
import { getDeviceKey, } from '../../../ResultAssignment/fragments/SessionDetailsBox';
import { selectAccordionSessionId } from '../../../../store/uiState';
import { useAppSelector } from '../../../../store';
import { getFullNameOrCode } from '../../../ResultAssignment/fragments/PinnedPatientList';
import CloseIcon from '@mui/icons-material/Close';
import { usePinPatientToDeviceMutation } from '../../../../api/deviceApi';
import ConfirmDialog from '../../../CloudMember/ConfirmDialog/ConfirmDialog';
import { t } from 'i18next';
import { useState } from 'react';

const DeviceBox = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '5px',
    marginRight: '10px',
    marginTop: '10px',
    height: '100px',
    minWidth: '200px',
    borderRadius: '5px',
    boxShadow: '1px 3px 6px #00000029',

});

const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: 'none',
    '& .MuiAccordionSummary-root': {
        padding: '0px 0px 0px 0px',
    },
    '& .MuiAccordionDetails-root': {
        padding: '20px 0px 20px 0px',
        borderTop: `2px solid ${theme.palette.lightgreen.main}`,
        borderBottom: `2px solid ${theme.palette.lightgreen.main}`,
    },
    '&:first-of-type': {
        borderTop: `1px solid  ${theme.palette.inactive.main}`,
    },
    '&:not(:last-child)': {
        '& .MuiAccordionSummary-root': {
            '&:not(.Mui-expanded)': {
                borderBottom: `1px solid  ${theme.palette.inactive.main}`,
            }
        },
    },
    '&::before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiAccordionSummary-expandIconWrapper': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(-90deg)',
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
}));

type Props = {
    patients: Patient[];
    patient: Patient;
    name: string;
    handleChange: (panel: string) => void;
    devices: DeviceMessage[];
};

const DeviceTypeAccordion = ({ patients, patient, devices, name, handleChange }: Props) => {
    const [pinPatientToDevice] = usePinPatientToDeviceMutation();
    const accordionSessionId = useAppSelector(selectAccordionSessionId);
    const [currentDevice, setCurrentDevice] = useState<DeviceMessage>();

    const setClearPatient = (device: DeviceMessage) => {
        setCurrentDevice(_device => undefined);
        if (device.patientId) {
            pinPatientToDevice({ deviceId: device.id });
            return;
        }
        pinPatientToDevice({ deviceId: device.id, patientId: patient.id });
    };

    const renderDeviceBox = (device: DeviceMessage) => {
        if (device.patientId) {
            return (
                <DeviceBox key={device.serial}
                    sx={{ backgroundColor: theme.palette.inactive.main, border: 'solid 2px green' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Box sx={{
                            display: 'flex',
                        }}>
                            <LyraIcon />
                        </Box>
                        <Box sx={{
                            cursor: 'pointer',
                            display: 'flex',
                            justifyContent: 'end'
                        }}
                            onClick={() => setCurrentDevice(device)}
                        >
                            <CloseIcon />
                        </Box>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                    }}>
                        {device.name}
                    </Box>
                    <Box sx={{
                        display: 'flex',
                    }}>
                        <Typography fontSize={smallFont}>
                            {patients.map(p => p.id == device.patientId ? t('device-assigned-label', { name: getFullNameOrCode(p) }) : '')}
                        </Typography>
                    </Box>
                </DeviceBox>);
        }
        return (
            <DeviceBox key={device.serial} onClick={() => setClearPatient(device)}
                sx={{
                    cursor: 'pointer', border: `solid 2px ${theme.palette.linkgray.main}`
                }}>
                <Box sx={{
                    display: 'flex',
                }}>
                    <LyraIcon />
                </Box>
                <Box sx={{
                    display: 'flex',
                }}>
                    {device.name}
                </Box>

                <Box sx={{
                    display: 'flex',
                }}>
                    <Typography fontSize={smallFont}>&nbsp;</Typography>
                </Box>

            </DeviceBox>);
    };

    return <Accordion key={name} expanded={accordionSessionId === name} onChange={() => handleChange(name)}>
        <AccordionSummary id={`${name}-header`}>
            <LyraIcon style={{ fontSize: '28pt' }} />
            <Typography sx={{ paddingTop: '6px', paddingLeft: '10px' }} fontSize={largeFont}>{name}</Typography>
        </AccordionSummary>
        <AccordionDetails>
            <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'flex-start' }}>
                {devices.filter(device => getDeviceKey(device) === name).map(device => renderDeviceBox(device))}
            </Box>
        </AccordionDetails>
        {currentDevice && <ConfirmDialog title={t('device-remove-assignment-title')}
            isVisible={currentDevice !== undefined}
            message={t('device-remove-assignment-message')}
            okCallback={() => setClearPatient(currentDevice)}
            cancelCallback={() => { setCurrentDevice(undefined); }} />}

    </Accordion>;
};

export default DeviceTypeAccordion;
