import { useEffect, useState } from "react";
import InviteMemberDialog from "./InviteMemberDialog";
import { useGetRetailerTokenMutation } from "../../../../api/retailerApi";
import { isEmpty } from "../../../ResultAssignment/fragments/SessionDetailsBox";

type DialogProps = {
    okCallback: () => void;
};
const InviteManufacturerDialog = ({ okCallback }: DialogProps) => {
    const [getToken] = useGetRetailerTokenMutation();
    const [token, setToken] = useState('');

    useEffect(() => {
        if (isEmpty(token)) {
            getToken().then((result) => {
                if (result.data && 'data' in result) {
                    if ('token' in result.data) {
                        setToken(() => result.data.token);
                    }
                }
            });
        }
    }, [getToken, token]);

    return <InviteMemberDialog okCallback={okCallback} token={token} />;
};

export default InviteManufacturerDialog;
