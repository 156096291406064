import { SetStateAction } from "react";
import { Pages } from "../../CloudMember/MemberListPanels/listPanelGlobals";
import { useGetDeviceQuery } from "../../../api/deviceApi";
import EditLyraDevice from "./DeviceTypePanel/EditLyraDevice";

type DetailsProps = {
    setPage: (value: SetStateAction<Pages>) => void;
    deviceId: string;
};

const EditDevice = ({ deviceId, setPage }: DetailsProps) => {
    const { data, isLoading } = useGetDeviceQuery(deviceId);//, { refetchOnMountOrArgChange: true });

    if (isLoading) {
        return <div>Loading...</div>;
    }

    const renderDevicePanel = () => {
        if (data?.type === 'lyra') {
            return <EditLyraDevice setPage={setPage} data={data} />;
        }
        return <>Error: unknown device type</>;
    };

    return renderDevicePanel();
};

export default EditDevice;
