import { SetStateAction, useEffect } from "react";
import { enqueueSnackbar } from "notistack";
import { Pages } from "../../CloudMember/MemberListPanels/listPanelGlobals";
import EditCloudMember from "../../CloudMember/DetailsPanel/EditCloudMember";
import { useGetClinicQuery, useUpdateClinicMutation } from "../../../api/clinicApi";

const ErrorMessage = (message: any) => {
    return (<div style={{ padding: '5px' }}>{message}</div>);
};

type DetailsProps = {
    setPage: (value: SetStateAction<Pages>) => void;
    clinicId: string;
};

const EditClinic = ({ clinicId, setPage }: DetailsProps) => {
    const { data, isLoading } = useGetClinicQuery(clinicId);//, { refetchOnMountOrArgChange: true });
    const [updateClinic, { error }] = useUpdateClinicMutation();

    useEffect(() => {
        if (error) {
            enqueueSnackbar(ErrorMessage(error), { variant: 'error' });

        }
    }, [error]);

    if (isLoading) {
        return <div>loading...</div>;
    }

    const update = async (params: any) => {
        updateClinic(params);
        setPage(Pages.DETAILS);
    };

    return (
        <EditCloudMember update={update} data={data} setPage={setPage} />
    );
};

export default EditClinic;
