import { styled } from '@mui/material';
import { ReactNode } from 'react';
import theme from '../../assets/theme';

const ContentArea = styled('div')({
    padding: '20px',
    backgroundColor: theme.palette.inactive.main,
    height: 'calc(100% - 150px)',
    width: '100%',
    position: 'absolute',
});
const ContentGrid = styled('div')({
    backgroundColor: '#FFF',
    boxShadow: '1px 3px 6px #00000029',
    width: '100%',
    height: '100%',
    borderRadius: '10px',
});

type ColumnProps = {
    component: ReactNode;
};
const SingleColumn = ({ component }: ColumnProps) => {
    return (
        <ContentArea>
            <ContentGrid>
                {component}
            </ContentGrid>
        </ContentArea >
    );
};

export default SingleColumn;
