import { styled, Button as MuiButton, ButtonProps } from '@mui/material';
import { largeFont, mediumFont } from '../../assets/theme';

const Button = styled(MuiButton)(({ theme }) => ({
    fontSize: largeFont,
    height: '45px',
    paddingLeft: '15px',
    paddingRight: '25px',
    '&:hover': {
        //backgroundColor: 'red',
    },
    '& .Mui-focusVisible': {

    }
}));

const StyledButton = styled(Button)({
    minWidth: 'min-content',
    whiteSpace: 'nowrap',
    fontSize: mediumFont,
});

const TheraButton = ({ ...props }: ButtonProps) => {
    props.variant = props.variant ? props.variant : "contained";
    props.size = props.size ? props.size : "small";

    return <StyledButton {...props} disableRipple={true} disableTouchRipple={true} disableElevation={true} disableFocusRipple={true} />;
};

export default TheraButton;
