
import { useGetPatientQuery } from "../../../../api/patientApi";
import { getFullNameOrCode } from "../../../ResultAssignment/fragments/PinnedPatientList";
import Avatar from "../../../Avatar/Avatar";

type Props = {
    patientId: string;
};

const PatientIdAvatar = ({ patientId }: Props) => {
    const { data, isLoading } = useGetPatientQuery(patientId);
    if (isLoading || !data) {
        return <></>;
    }

    return (<Avatar
        size="small" name={getFullNameOrCode(data)} />);
};
export default PatientIdAvatar;
