import { Box, Divider, Grid, Stack } from "@mui/material";
import { ReactNode } from "react";
import Avatar from "../../Avatar/Avatar";
import DetailsHeader from "./DetailsHeader";
import { CloudMember } from "../../../types/types";
import { t } from "i18next";
import StyledText from "../../StyledTextField/StyledText";
import StyledLabel from "../../StyledTextField/StyledLabel";
import FooterText from "../../StyledTextField/FooterText";
import { dateString } from "../../ResultAssignment/fragments/SessionDetailsBox";

type DetailsProps = {
    data: CloudMember | undefined;
    editEntry?: () => void;
    deleteEntry?: () => void;
    switchActive?: () => void;
    deleteEntryMessage?: ReactNode;
    undeleteEntryMessage?: ReactNode;
    memberType: string;
};

const CloudMemberDetails = ({ memberType, switchActive, deleteEntry, editEntry, deleteEntryMessage, undeleteEntryMessage, data }: DetailsProps) => {
    return (
        <Box sx={{ height: '100%', padding: '20px' }} >
            <Stack direction="column" sx={{ height: '100%', overflow: 'auto' }} >
                <Stack direction="row" spacing={2}>
                    <Avatar size="large" name={data?.name} />
                    <DetailsHeader name={data?.name ?? ''}
                        memberType={memberType}
                        isActive={Boolean(data?.active)}
                        isDeleted={Boolean(data?.deleted)}
                        switchActive={switchActive}
                        editEntry={editEntry}
                        deleteEntry={deleteEntry}
                        deleteEntryMessage={deleteEntryMessage}
                        undeleteEntryMessage={undeleteEntryMessage}
                    />

                </Stack>
                <Divider sx={{ marginTop: '20px' }} />
                <Stack pt="15px" direction="column" spacing={0}>
                    <StyledLabel>{t('cloud-member-form-comment-label')}</StyledLabel>
                    <StyledText>{data?.comment}</StyledText>
                </Stack>

                <Stack pt="20px" direction="row" spacing={2}>
                    <StyledLabel sx={{ width: '50%' }}>PLZ</StyledLabel>
                    <StyledLabel>{t('cloud-member-form-city-label')}</StyledLabel>
                </Stack>
                <Stack direction="row" spacing={2}>
                    <StyledText sx={{ width: '50%' }}>{data?.zip}</StyledText>
                    <StyledText>{data?.city}</StyledText>
                </Stack>
                <Stack pt="20px" direction="row" spacing={2}>
                    <StyledLabel sx={{ width: '50%' }}>Straße</StyledLabel>
                    <StyledLabel>{t('cloud-member-form-street-nr-label')}</StyledLabel>
                </Stack>
                <Stack direction="row" spacing={2}>
                    <StyledText sx={{ width: '50%' }}>{data?.street}</StyledText>
                    <StyledText>{data?.streetNr}</StyledText>
                </Stack>

                <Stack pt="20px" direction="column" spacing={0}>
                    <StyledLabel>{t('cloud-member-form-contact-label')}</StyledLabel>
                    <StyledText>{data?.contact}</StyledText>
                </Stack>
                <Stack pt="20px" direction="column" spacing={0}>
                    <StyledLabel>{t('cloud-member-form-phone-label')}</StyledLabel>
                    <StyledText>{data?.phone}</StyledText>
                </Stack>
                <Stack pt="20px" direction="column" spacing={0}>
                    <StyledLabel>{t('cloud-member-form-email-label')}</StyledLabel>
                    <StyledText>{data?.email}</StyledText>
                </Stack>
                <Stack style={{ justifyContent: 'end', height: '100%', paddingTop: '20px' }} >
                    <Divider sx={{ marginBottom: '15px' }} />
                    <Grid container>
                        <Grid item xs={6}>
                            <FooterText fontWeight="bold">{t('cloud-member-form-updated-label')}</FooterText>
                        </Grid>
                        <Grid item xs={6} sx={{ textAlign: 'right' }}>
                            <FooterText fontWeight="bold">{t('cloud-member-form-created-label')}</FooterText>
                        </Grid>
                        <Grid item xs={6}>
                            <FooterText>{dateString(data?.lastUpdated ?? data?.created)}</FooterText>
                        </Grid>
                        <Grid item xs={6} sx={{ textAlign: 'right' }}>
                            <FooterText>{dateString(data?.created)}</FooterText>
                        </Grid>
                    </Grid>
                </Stack>
            </Stack>
        </Box >
    );
};

export default CloudMemberDetails;
