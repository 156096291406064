import { useEffect, useState } from "react";
import { MappedTherapySessionData } from "../types/types";
import { useGetUnassignedTherapieSessionsQuery } from "../api/therapySessionsApi";
import useFetchDevices from "./useFetchDevices";

const useFetchUnassignedSessions = () => {
    const { isDevicesLoading, devices, devicesError } = useFetchDevices();
    const { isLoading, data, error } = useGetUnassignedTherapieSessionsQuery(undefined, { refetchOnMountOrArgChange: 60 });
    const [unassignedSessions, setUnassignedSessions] = useState<MappedTherapySessionData[]>([]);
    const [isUnassignedSessionsLoading, setIsUnassignedSessionsLoading] = useState(false);
    const [unassignedSessionsError, setUnassignedSessionsError] = useState<string | null>(null);

    useEffect(() => {
        setIsUnassignedSessionsLoading(isLoading);
        if (error) {
            setUnassignedSessionsError(JSON.stringify(error));
            setIsUnassignedSessionsLoading(false);
        } else if (devicesError) {
            setUnassignedSessionsError(devicesError.toString());
            setIsUnassignedSessionsLoading(false);
        } else if (!isLoading && !isDevicesLoading && devices && data) {
            setUnassignedSessions(data);
            setUnassignedSessionsError(null);
            setIsUnassignedSessionsLoading(false);
        }
    }, [isLoading, data, error, isDevicesLoading, devices, devicesError]);

    return { isUnassignedSessionsLoading, unassignedSessions, unassignedSessionsError };

};

export default useFetchUnassignedSessions;
