import { memo, useState } from 'react';
import { t } from 'i18next';
import PatientList from './fragments/PatientList';
import ButtonBar from '../User/fragments/ButtonBar';
import SingleColumn from '../PageTemplates/SingleColumn';
import { useNavigate } from 'react-router-dom';
import { SelectedPatient } from '../ResultAssignment/ResultAssignment';
import NewPatientDialog from './fragments/NewPatientDialog';
import { useAppDispatch } from '../../store';
import { setCurrentPatient } from '../../store/uiState';

const MemoPatientsList = memo(PatientList);

const Patients = () => {
    const [selectedPatient, setSelectedPatient] = useState<SelectedPatient>({ name: '', id: '', patientId: '' });
    const navigate = useNavigate();
    const [showAddPatientDialog, setShowAddPatientDialog] = useState(false);
    const dispatch = useAppDispatch();

    const showDetails = (patient: SelectedPatient, scrollPos: number) => {
        setSelectedPatient(() => patient);
    };

    const showDashboard = (id: string) => {
        dispatch(setCurrentPatient(id));
        navigate('/patients/info', { state: { id: id } });
    };

    const openTrainingsResults = (id: string) => {
        dispatch(setCurrentPatient(id));
        navigate('/patients/results', { state: { id: id } });
    };

    const openTrainingSettings = (id: string) => {
        dispatch(setCurrentPatient(id));
        navigate('/patients/settings', { state: { id: id } });
    };

    return (
        <SingleColumn component={
            <div style={{ width: 'calc(100% - 20px)', height: '100%', paddingLeft: '20px' }}>
                <ButtonBar
                    searchFieldPlaceholder={t('patient-searchfield-placeholder')}
                    addEntryLabel={t('patient-add-button')}
                    showAddButton={true}
                    addNewEntry={() => { setShowAddPatientDialog(true); }} />
                <MemoPatientsList openSettings={openTrainingSettings} openResults={openTrainingsResults} showDashboard={showDashboard} selectedPatient={selectedPatient} selectPatient={showDetails} scrollPos={0} />
                {showAddPatientDialog && <NewPatientDialog onClose={() => setShowAddPatientDialog(false)} />}

            </div>}
        />
    );

};

export default Patients;
