import { Stack, TableContainer, Typography, useTheme } from '@mui/material';
import { useState } from 'react';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import SearchField from './fragments/SearchField';
import LockedAccountsList from './fragments/LockedAccountsList';
import { useRemoveAccountLockMutation } from '../../api/userApi';
import SingleColumn from '../PageTemplates/SingleColumn';
import { useAppDispatch, useAppSelector } from '../../store';
import { selectListFilter, setListFilter } from '../../store/listStateSlice';
import TheraButton from '../TheraButtons/TheraButton';
import { largeFont } from '../../assets/theme';

const LockedAccounts = () => {
    const dispatch = useAppDispatch();
    const filter = useAppSelector(selectListFilter);
    const [removeAccountLock] = useRemoveAccountLockMutation();
    const theme = useTheme();
    const [selected, setSelected] = useState<string[]>([]);

    const setSelectedAccounts = (selectedAccounts: string[]) => {
        setSelected(selectedAccounts);
    };


    const unlockAccounts = () => {
        const filteredAccounts = selected.filter((account) => account.indexOf(filter) >= 0);
        filteredAccounts.forEach(async email => {
            await removeAccountLock(email);
        });
    };

    return (
        <SingleColumn component={
            <TableContainer sx={{
                height: '100%',
                overflow: 'auto',
                borderRadius: '10px', paddingLeft: '30px', paddingRight: '30px'
            }}>
                <Typography color="primary" fontWeight="bold" fontSize={largeFont} sx={{ marginTop: '20px', marginBottom: '10px', height: '35px' }}>Locked Accounts</Typography>
                <hr style={{ color: theme.palette.inactive.main, marginBottom: '40px' }} />
                <Stack direction="row">
                    <SearchField onChange={(value) => { dispatch(setListFilter(value)); }} />
                    <TheraButton color="success" variant="contained" onClick={() => unlockAccounts()}><CheckCircleOutlineIcon sx={{ marginLeft: '5px', marginRight: '10px' }} />Unlock</TheraButton >
                </Stack>
                <LockedAccountsList filter={filter} unlockAccounts={setSelectedAccounts} />
            </TableContainer>} />
    );
};

export default LockedAccounts;

