import { CloudMember, ConnectToken, CloudMemberRequest, UpdateCloudMemberRequest } from '../types/types';
import { emptyBaseApi } from './emptyBaseApi';

export const retailerApi = emptyBaseApi.injectEndpoints({
    endpoints: (builder) => ({
        getRetailerList: builder.query<CloudMember[], void>({
            query: () => ({
                url: 'retailer/all',
                method: 'GET',

            }),
            providesTags: ['Retailer'],
        }),
        getAllRetailer: builder.mutation<CloudMember[], void>({
            query: () => ({
                url: 'retailer/all',
                method: 'GET',

            }),
        }),
        addRetailer: builder.mutation<CloudMember, CloudMemberRequest>({
            query: (retailer) => ({
                url: 'retailer/signup',
                method: 'POST',
                body: retailer,
            }),
            invalidatesTags: ['Retailer', 'User']
        }),
        deleteRetailer: builder.mutation({
            query: (id) => ({
                url: `retailer/remove?id=${id}`,
                method: 'GET',
            }),
            invalidatesTags: ['Retailer']
        }),
        getRetailerToken: builder.mutation<ConnectToken, void>({
            query: () => ({
                url: 'retailer/get_retailer_token',
                method: 'GET',
            }),
        }),
        acceptClinicToken: builder.mutation<ConnectToken, ConnectToken>({
            query: (token) => ({
                url: 'retailer/accept_clinic_token',
                method: 'POST',
                body: token,
            }),
            invalidatesTags: ['Clinic', 'Retailer', 'User']
        }),

        updateRetailer: builder.mutation<CloudMember, UpdateCloudMemberRequest>({
            query: (retailer) => ({
                url: 'retailer/update',
                method: 'POST',
                body: retailer,
            }),
            invalidatesTags: ['Retailer']
        }),
        switchRetailerActive: builder.mutation<CloudMember, string>({
            query: (id) => ({
                url: `retailer/switch_active?id=${id}`,
                method: 'GET',
            }),
            invalidatesTags: ['Retailer']
        }),
        undeleteRetailer: builder.mutation({
            query: (id) => ({
                url: `retailer/undelete?id=${id}`,
                method: 'GET',
            }),
            invalidatesTags: ['Retailer']
        }),
        getRetailer: builder.query<CloudMember, string>({
            query: (id) => ({
                url: `retailer/get?id=${id}`,
                method: 'GET',
            }),
            providesTags: ['Retailer'],
        }),
        fetchRetailer: builder.mutation<CloudMember, string>({
            query: (id) => ({
                url: `retailer/get?id=${id}`,
                method: 'GET',
            }),
        }),
    }),
    overrideExisting: true,
});

export const { useFetchRetailerMutation, useAcceptClinicTokenMutation, useGetAllRetailerMutation, useUndeleteRetailerMutation, useGetRetailerTokenMutation, useGetRetailerQuery, useUpdateRetailerMutation, useSwitchRetailerActiveMutation, useGetRetailerListQuery, useDeleteRetailerMutation, useAddRetailerMutation } = retailerApi;
