import { styled, CssBaseline, ThemeProvider, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import theme from './assets/theme';
import AuthenticationProvider from './components/AuthenticationProvider/AuthenticationProvider';
import { PageHeader } from './components/PageHeader/PageHeader';
import { BrowserRouter as Router } from 'react-router-dom';
import { SnackbarKey, SnackbarProvider, closeSnackbar } from 'notistack';
import InactivityLogoutProvider from './components/InactivityLogoutProvider/InactivityLogoutProvider';
import { setDefaultOptions } from 'date-fns';
import { de } from 'date-fns/locale';
import useRoutes from './config/routes';
import i18n from './i18n';
import { useEffect } from 'react';
import { useAppSelector } from './store';
import { selectEmail } from './store/authSlice';
import 'rsuite/dist/rsuite.min.css';
import './index.css';
import NavigationSwitch from './components/NavigationHeader/NavigationSwitch';
export const StyledContainer = styled('div')({
  padding: '0px',
  margin: '0px',
  position: 'absolute',
  height: '100%',
  width: '100%',
});

const App = () => {
  const username = useAppSelector(selectEmail);
  setDefaultOptions({ locale: de });
  i18n.changeLanguage('de');

  useEffect(() => {
    if (username) {
      document.title = `THERA-Cloud - ${username}`;
    } else {
      document.title = 'THERA-Cloud';
    }

  }, [username]);

  const onClickDismiss = (key: SnackbarKey) => {
    closeSnackbar(key);
  };

  const renderSnackbar = (snackbarKey: SnackbarKey) => {
    return (<IconButton sx={{ color: 'white' }} onClick={() => onClickDismiss(snackbarKey)}><CloseIcon stroke={theme.palette.linkgray.main} /></IconButton>);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <SnackbarProvider action={renderSnackbar}>
        <Router>
          <AuthenticationProvider>
            <InactivityLogoutProvider />
            <StyledContainer>
              <PageHeader />
              <NavigationSwitch />
              {useRoutes()}
            </StyledContainer>
          </AuthenticationProvider>
        </Router>
      </SnackbarProvider>
    </ThemeProvider >
  );
};
export default App;
