// Need to use the React-specific entry point to allow generating React hooks
import { ListUserResponse, LockedUser, RefreshTokenResponse, User, UserUpdate } from '../types/types';
import { emptyBaseApi } from './emptyBaseApi';


export const userApi = emptyBaseApi.injectEndpoints({
    endpoints: (builder) => ({
        getUserList: builder.query<ListUserResponse, void>({
            query: () => ({
                url: 'user/all',
                method: 'GET',
            }),
            providesTags: ['User'],
        }),
        getLockedUserList: builder.query<LockedUser[], void>({
            query: () => ({
                url: 'user/locked',
                method: 'GET',
            }),
            providesTags: ['LockedUser'],
        }),
        removeAccountLock: builder.mutation({
            query: (email) => ({
                url: `user/remove_account_loc?email=${email}`,
                method: 'GET',
            }),
            invalidatesTags: ['LockedUser']
        }),
        addUser: builder.mutation<User, Omit<User, 'id'>>({
            query: (user) => ({
                url: 'user/signup',
                method: 'POST',
                body: user,
            }),
            invalidatesTags: ['User']
        }),
        updateUser: builder.mutation<User, UserUpdate>({
            query: (user) => ({
                url: 'user/update',
                method: 'POST',
                body: user,
            }),
            invalidatesTags: ['User']
        }),
        getUser: builder.query<User, string>({
            query: (id) => ({
                url: `user/get?id=${id}`,
                method: 'GET',
            }),
            providesTags: ['User'],
        }),
        switchUserActive: builder.mutation({
            query: (id) => ({
                url: `user/switch_active?id=${id}`,
                method: 'GET',
            }),
            invalidatesTags: ['User']
        }),
        deleteUser: builder.mutation({
            query: (id) => ({
                url: `user/remove?id=${id}`,
                method: 'GET',
            }),
            invalidatesTags: ['User']
        }),
        undeleteUser: builder.mutation({
            query: (id) => ({
                url: `user/undelete?id=${id}`,
                method: 'GET',
            }),
            invalidatesTags: ['User']
        }),
        refreshAccessToken: builder.mutation<RefreshTokenResponse, { refreshToken: string; }>({
            query: (token) => ({
                url: 'auth/refreshtoken',
                method: 'POST',
                body: token,
            }),
        }),
    }),
    overrideExisting: true,
});

export const { useUndeleteUserMutation, useSwitchUserActiveMutation, useGetUserQuery, useGetUserListQuery, useRefreshAccessTokenMutation, useUpdateUserMutation, useDeleteUserMutation, useAddUserMutation, useGetLockedUserListQuery, useRemoveAccountLockMutation } = userApi;
