import { useEffect, useState } from "react";
import { CloudMember } from "../types/types";
import { useGetManufacturerListQuery } from "../api/manufacturerApi";

const useFetchManufacturers = () => {
    const { isLoading, data, error } = useGetManufacturerListQuery(undefined, { refetchOnMountOrArgChange: true });
    const [manufacturers, setManufacturers] = useState<CloudMember[]>();
    const [isManufacturersLoading, setIsManufacturersLoading] = useState(false);
    const [manufacturersError, setManufacturersError] = useState<string | null>(null);

    useEffect(() => {
        setIsManufacturersLoading(isLoading);
        if (error) {
            setIsManufacturersLoading(false);
            setManufacturersError(JSON.stringify(error));
        } else if (!isLoading && data) {
            if (JSON.stringify(data) !== JSON.stringify(manufacturers)) {
                setManufacturers(data);
                setManufacturersError(null);
            }
            setIsManufacturersLoading(false);
        }
    }, [manufacturers, isLoading, data, error]);

    return { isManufacturersLoading, manufacturers, manufacturersError };

};

export default useFetchManufacturers;
