import { Button, Stack, styled } from "@mui/material";
import FilterButton from "../../CloudMember/MemberListPanels/FilterButton";
import SearchField from "../../CloudMember/MemberListPanels/SearchField";
import { useAppDispatch, useAppSelector } from "../../../store";
import { selectListFilter, setListFilter } from "../../../store/listStateSlice";
import { largeFont } from "../../../assets/theme";

const AddButton = styled(Button)({
    minWidth: 'min-content',
    fontSize: largeFont,
    whiteSpace: 'nowrap',
});



type ButtonBarProps = {
    addNewEntry: () => void;
    showAddButton: boolean;
    addEntryLabel: string;
    searchFieldPlaceholder: string;
    showFilter?: boolean;
};

const ButtonBar = ({ showFilter = true, searchFieldPlaceholder, addEntryLabel, showAddButton, addNewEntry }: ButtonBarProps) => {
    const dispatch = useAppDispatch();
    const filter = useAppSelector(selectListFilter);

    return (
        <Stack paddingLeft="20px" paddingRight="20px" paddingTop="20px" paddingBottom="15px" spacing={2} direction="row" >
            <SearchField placeholder={searchFieldPlaceholder} value={filter} onChange={(value) => { dispatch(setListFilter(value)); }} />
            {showFilter && <FilterButton />}
            {showAddButton &&
                <AddButton onClick={() => addNewEntry()} color="success" variant="contained" disableRipple={true}> {addEntryLabel}</AddButton >
            }
        </Stack >

    );
};
export default ButtonBar;
