import { Box, Button, Divider, Popover, Stack, Typography, styled } from "@mui/material";
import FilterIcon from "../../../icons/FilterIcon";
import { t } from "i18next";
import TwoArrowsDownIcon from "../../../icons/TwoArrowsDownIcon";
import TwoArrowsUpIcon from "../../../icons/TwoArrowsUpIcon";
import { useRef, useState } from "react";
import FilterCheckbox from "./FilterCheckbox";
import { useAppSelector, } from "../../../store";
import { selectActiveFilter, selectAllFilter, selectDeletedFilter, selectInactiveFilter, switchActiveFilter, switchAllFilter, switchDeletedFilter, switchInactiveFilter } from "../../../store/filterState";
import FilterCount from "./FilterCount";
import { selectIsSystemaAdmin } from "../../../store/authSlice";
import { largeFont, smallFont, tinyFont } from "../../../assets/theme";

const ArrowUp = styled(TwoArrowsUpIcon)(({ theme }) => ({
    fontSize: tinyFont,
    '& path': {
        fill: theme.palette.primary.main
    }
}));

const ArrowDown = styled(TwoArrowsDownIcon)(({ theme }) => ({
    fontSize: tinyFont,
    '& path': {
        fill: theme.palette.primary.main
    }
}));

const Label = styled('div')({
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    paddingRight: '40px'
});

export const Active = styled('div')(({ theme }) => ({
    display: 'flex',
    paddingLeft: '10px',
    paddingRight: '10px',
    width: 'min-content',
    fontSize: smallFont,
    justifyContent: 'center',
    alignItems: 'center',
    height: '24px',
    borderRadius: '5px',
    backgroundColor: 'transparent',
    color: theme.palette.active.contrastText,
    border: `solid 2px ${theme.palette.active.contrastText}`

}));

export const Deleted = styled('div')(({ theme }) => ({
    display: 'flex',
    paddingLeft: '10px',
    paddingRight: '10px',
    width: 'min-content',
    fontSize: smallFont,
    justifyContent: 'center',
    alignItems: 'center',
    height: '24px',
    borderRadius: '5px',
    backgroundColor: 'transparent',
    color: theme.palette.deleted.contrastText,
    border: `solid 2px ${theme.palette.deleted.contrastText}`

}));

export const Inactive = styled('div')(({ theme }) => ({
    display: 'flex',
    paddingLeft: '10px',
    paddingRight: '10px',
    width: 'min-content',
    fontSize: smallFont,
    justifyContent: 'center',
    alignItems: 'center',
    height: '24px',
    borderRadius: '5px',
    backgroundColor: 'transparent',
    color: theme.palette.inactive.contrastText,
    border: `solid 2px ${theme.palette.inactive.contrastText}`
}));

const StyledFilterButton = styled(Button)(({ theme }) => ({
    minWidth: 'min-content',
    borderColor: theme.palette.lightgreen.main,
    backgroundColor: theme.palette.lightgreen.main,
    color: theme.palette.primary.main,
    fontSize: largeFont,
    whiteSpace: 'nowrap',
    "&:hover": {
        backgroundColor: theme.palette.lightgreen.main,
    },
    "&:active": {
        backgroundColor: theme.palette.lightgreen.main,
    },
    '& .MuiButton-startIcon': {
        '& > *:nth-of-type(1)': {
            fontSize: largeFont,
        },
    },
}));

const FilterButton = () => {
    const isSystemAdmin = useAppSelector(selectIsSystemaAdmin);
    const ref = useRef();
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>();
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (open) {
            return;
        }
        setAnchorEl(ref.current);
    };

    const hideMenu = () => {
        setAnchorEl(undefined);
    };

    const renderFilterDropDown = () => {
        return (
            <Popover
                open={open}
                onClose={hideMenu}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                sx={{
                    userSelect: 'none'
                }}
            >
                <Stack direction="column">

                    <Stack direction="row">
                        <FilterCheckbox switchFilter={switchAllFilter} selectFilter={selectAllFilter} />
                        <Label>Alle</Label>
                    </Stack>
                    <Divider />

                    <Stack direction="row" sx={{}}>
                        <FilterCheckbox switchFilter={switchActiveFilter} selectFilter={selectActiveFilter} />
                        <Label>{t('active-label')}</Label>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'end', width: '100%', paddingRight: '10px' }}>
                            <Active sx={{ display: 'flex', justifySelf: 'end' }}>{t('active-label')}</Active></div>
                    </Stack>

                    <Divider />

                    <Stack direction="row">
                        <FilterCheckbox switchFilter={switchInactiveFilter} selectFilter={selectInactiveFilter} />
                        <Label>{t('inactive-label')}</Label>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'end', width: '100%', paddingRight: '10px' }}><Inactive sx={{ display: 'flex', justifySelf: 'end' }}>{t('inactive-label')}</Inactive></div>
                    </Stack>
                    {isSystemAdmin && <>
                        <Divider />
                        <Stack direction="row">
                            <FilterCheckbox switchFilter={switchDeletedFilter} selectFilter={selectDeletedFilter} />
                            <Label>{t('deleted-label')}</Label>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'end', width: '100%', paddingRight: '10px' }}>
                                <Deleted sx={{ display: 'flex', justifySelf: 'end' }}>{t('deleted-label')}</Deleted></div>
                        </Stack></>}
                </Stack>

            </Popover >
        );
    };

    return (
        <Box ref={ref} onClick={handleClick}>
            <StyledFilterButton variant="contained" disableRipple={true}>
                <FilterIcon sx={{ marginLeft: '0px', marginRight: '10px' }} />
                <Typography sx={{ textAlign: 'left', paddingRight: '10px' }} fontSize="22px">{t('filter-button')}</Typography>
                <FilterCount />
                {open ? <ArrowUp /> : <ArrowDown />}
            </StyledFilterButton>
            {renderFilterDropDown()}
        </Box >
    );
};

export default FilterButton;
