
import { Stack } from "@mui/material";
import { useGetPatientQuery } from "../../../../api/patientApi";
import Avatar from "../../../Avatar/Avatar";
import { getFullNameOrCode } from "../../../ResultAssignment/fragments/PinnedPatientList";
import DetailsHeader from "../../../CloudMember/DetailsPanel/DetailsHeader";

type Props = {
    patientId: string;
};

const PatientAvatar = ({ patientId }: Props) => {
    const { data, isLoading } = useGetPatientQuery(patientId);

    if (isLoading || !data) {
        return <></>;
    }

    return (
        <Stack direction="row" sx={{ width: '100%', padding: '20px 20px 0px 20px' }} spacing={(2)}>
            <Avatar size="large" name={getFullNameOrCode(data)} />
            <DetailsHeader name={getFullNameOrCode(data)}
                memberType={`Patient ${data.patientCode}`}
                isActive={true}
                isDeleted={false}
            />
        </Stack>
    );
};
export default PatientAvatar;
