import { useLocation } from "react-router-dom";
import { MainNavigation } from "./MainNavigation";
import { PatientsNavigation } from "./PatientsNavigation";

const NavigationSwitch = () => {
    const location = useLocation();

    const getNavigation = () => {
        return location.pathname.startsWith('/patients/')
            ?
            <PatientsNavigation />
            :
            <MainNavigation />;
    };

    return (getNavigation());
};

export default NavigationSwitch;
