import { memo, useEffect, useState } from 'react';
import RetailerDetails from './fragments/RetailerDetails';
import TwoColumns from '../PageTemplates/TwoColumns';
import EditRetailer from './fragments/EditRetailer';
import { useAppDispatch, useAppSelector } from '../../store';
import { selectIsClinicAdmin, selectIsManufacturerAdmin } from '../../store/authSlice';
import ListPanelButtonBar from '../CloudMember/MemberListPanels/ListPanelButtonBar';
import { Pages } from '../CloudMember/MemberListPanels/listPanelGlobals';
import { useAddRetailerMutation, useGetRetailerListQuery } from '../../api/retailerApi';
import AddCloudMember from '../CloudMember/DetailsPanel/AddCloudMember';
import CloudMemberList from '../CloudMember/MemberListPanels/CloudMemberList';
import { t } from 'i18next';
import { resetFilter } from '../../store/filterState';
import { resetListState } from '../../store/listStateSlice';

const MemoCloudMemberList = memo(CloudMemberList);

const Retailer = () => {
    const [retailerId, setRetailerId] = useState("");
    const [page, setPage] = useState(Pages.DETAILS);
    const isManufacturerAdmin = useAppSelector(selectIsManufacturerAdmin);
    const isClinicAdmin = useAppSelector(selectIsClinicAdmin);

    const [addRetailer] = useAddRetailerMutation();
    const { isLoading, data } = useGetRetailerListQuery(undefined, { refetchOnMountOrArgChange: true });
    const [scrollPos, setScrollPos] = useState(0);
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(resetFilter());
        dispatch(resetListState());
    }, [dispatch]);

    useEffect(() => {
        if (!isLoading && data && data.length > 0) {
            if (retailerId && retailerId !== "") {
                const selectedMember = data.find((entry) => entry.id === retailerId);
                if (!selectedMember) {
                    showDetails(data[0].id, scrollPos);
                }
            } else {
                showDetails(data[0].id, scrollPos);
            }
        }
    }, [isLoading, data, retailerId, scrollPos, dispatch]);

    if (isLoading) {
        return <div>loading...</div>;
    }

    const showDetails = (id: string, scrollPos: number) => {
        setScrollPos(scrollPos);
        setRetailerId(() => id);
        setPage(Pages.DETAILS);
    };

    const addEntry = () => {
        setPage(Pages.ADD);
    };

    const getRightComponent = () => {
        switch (page) {
            case Pages.ADD:
                return (<AddCloudMember addMember={addRetailer} setPage={setPage} />);
            case Pages.EDIT:
                return (<EditRetailer setPage={setPage} retailerId={retailerId} />);
            case Pages.DETAILS:
                return (<>{retailerId && <RetailerDetails setPage={setPage} retailerId={retailerId} />}</>);
            default:
                if (retailerId) {
                    return (<RetailerDetails setPage={setPage} retailerId={retailerId} />);
                }
                return (<div style={{ padding: '20px' }}>Default Detailseite</div>);
        }

    };

    return (
        <TwoColumns leftComponent={
            <>
                <ListPanelButtonBar
                    acceptText={t('accept-retailer-dialog-text')}
                    searchFieldPlaceholder={t('retailer-searchfield-placeholder')}
                    addEntryLabel={isClinicAdmin ? t('retailer-invite-button') : t('retailer-add-button')}
                    showAddButton={isManufacturerAdmin}
                    showAcceptButton={isManufacturerAdmin}
                    showInviteButton={isClinicAdmin}
                    addNewEntry={addEntry} />
                <MemoCloudMemberList scrollPos={scrollPos} data={data} selectedId={retailerId} showDetails={showDetails} />
            </>}
            rightComponent={getRightComponent()} />
    );

};

export default Retailer;
